import React, { useState } from 'react'
import TablePatientDetail from './TablePatientDetail'
import { makeStyles } from '@mui/styles'
import ActionButton from './ActionButton'
import Animation from '@mui/material/Slide'
import { getEcgStatus, getPopoverInfo } from '../../utils/ecg'
import { noop } from '../../utils/misc'
import Popover from '@mui/material/Popover'
import { Chip, Typography } from '@mui/material'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: props => props.height,
    opacity: props => props?.isRead ? '70%' : '90%',
    '&:hover': {
      opacity: '100%',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    paddingLeft: 10,
  },
  popoverStyles: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  chipsStyles: {
    display: 'block',
  },
}))

const TableRow = props => {
  const { ecg, getValidActions = noop, index, userPreferences = [], userCenter, ...rest } = props
  const validActions = getValidActions(ecg, userCenter)
  const [isRead, setIsRead] = useState(ecg?.isRead)
  const isFirstRow = index === 0
  const height = 80
  const makeStylesProps = { height, isRead }
  const classes = useStyles(makeStylesProps)
  const status = getEcgStatus(ecg)
  const [anchorEl, setAnchorEl] = useState(null)
  const { validChips, summaryArr } = getPopoverInfo(ecg)
  const onHoverPref = userPreferences.find(userPref => userPref.preference.key === 'ECG_ON_HOVER_DETAIL') ?? { value: true }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Animation in direction='right'>
      <div
        className={classes.root}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div className={classes.row}>
          <TablePatientDetail
            key={ecg.id}
            isEditable={false}
            patientName={ecg.patient.name}
            ecgData={ecg}
            isFirstRow={isFirstRow}
            isRead={isRead}
            setIsRead={setIsRead}
            {...rest}
          />
          <div
            className={classes.button}
          >
            {validActions.map(action => (
              <ActionButton
                key={`${action.key}-${ecg.id}`}
                isFirstRow={isFirstRow}
                action={action}
                status={status}
                ecg={ecg}
                {...rest}
              />
            ))}
          </div>
        </div>
        {onHoverPref.value && (
          <Popover
            id='mouse-over-popover'
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            PaperProps={{
              elevation: 3,
              style: {
                minWidth: 350,
                maxWidth: 350,
                minHeight: 200,
                padding: 20,
                borderRadius: 25,
              }
            }}
          >
            <div className={classes.popoverStyles}>
              {summaryArr.map(element => (
                <div
                  key={element.field}
                  style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}
                >
                  <Typography>
                    {element.field}
                  </Typography>
                  <Typography>
                    {element.value}
                  </Typography>
                </div>
              ))}
              <div className={classes.chipsStyles}>
                {validChips.map(chipData => {
                  const { Icon } = chipData
                  return (
                    <Chip
                      key={chipData.label}
                      label={chipData.label}
                      color={chipData.color}
                      icon={<Icon {...chipData.iconProps} />}
                      size='small'
                      style={{ marginRight: 5 }}
                    />
                  )
                })}
              </div>
            </div>
          </Popover>
        )}
      </div>
    </Animation>
  )
}

export default TableRow
