import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import axios from 'axios'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { pdfjs } from 'react-pdf'
import { ReduxBundlerProvider as Provider } from 'redux-bundler-hook'
import createStore from './bundles'
import { types as authTypes } from './bundles/auth'
import Globals from './containers/Globals'
import App from './routes'
import theme from './theme'
// import LogRocket from 'logrocket'
// import setupLogRocketReact from 'logrocket-react'

export const sessionReplayEnvs = ['production']

// const logrocketId = process.env.REACT_APP_LOGROCKET
// if (logrocketId && sessionReplayEvns.includes(process.env.REACT_APP_ENVIRONMENT)) {
//   LogRocket.init(logrocketId)
//   setupLogRocketReact(LogRocket)
//   console.log('LOGROCKET SET UP CORRECTLY')
// }

let csrfToken = null
const baseUrl = process.env.REACT_APP_BASE_URL
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.baseURL = baseUrl
axios.defaults.withCredentials = false


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

export const store = createStore()
window.store = store
window.theme = theme

export const getCSRF = async () => {
  let response
  let data
  try {
    response = await axios.get('/api/csrf')
    data = response.data
    csrfToken = data?.csrfToken
    axios.defaults.headers.common['X-CSRFToken'] = csrfToken
    axios.defaults.withCredentials = false
  } catch (err) {
    store.doSetSnackbarFail('ERROR. Contacte a soporte: CSRF NOT LOADED. CODE 0x01')
  }
}

axios.interceptors.response.use(
  response => {return response},
  async error => {
    const originalConfig = error.config
    if (error.response) {
      if (error.response.status == 401 && !originalConfig._retry) {
        originalConfig._retry = true
        const refresh = localStorage.getItem('refresh')
        if (refresh) {
          let res
          try {
            res = await axios.post(
              '/api/token/refresh/',
              {refresh},
            )
            localStorage.setItem('access', res.data.access)
            store.dispatch({ type: authTypes.REFRESH_TOKEN, payload: res.data.access })
            await store.doLoadUser()
            return axios(originalConfig)
          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data)
            }
            return Promise.reject(_error)
          }
        } else {
          globalThis.localStorage.clear()
          store.doLogout(false)
        }
        if (error.response.status === 403 && error.response.data) {
          return Promise.reject(error.response.data)
        }
      }
      return Promise.reject(error)
    }
  }
)

const appDiv = document.getElementById('app')
const root = createRoot(appDiv)
root.render(
  <Provider store={store}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Globals />
      <App />
    </ThemeProvider>
  </Provider>,
)
