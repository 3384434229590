import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useConnect } from 'redux-bundler-hook'
import Checkbox from '@mui/material/Checkbox'
import T from '../common/Typography'
import { makeStyles } from '@mui/styles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import MultipleSelectChip from '../common/MultipleSelect'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 5,
  },
  exportTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  table: {},
  exportSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  exportHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    overflowX: 'scroll',
    textOverflow: 'ellipsis',
  },
  box: {
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 150,
    maxHeight: 50,
    // width: "100%"
  },
  orgButton: {
    width: '100%',
    maxWidth: 150,
    maxHeight: 50,
    overflow: 'hidden',
    fontSize: '0.8rem',
    '& .MuiButton-label': {
      fontSize: '0.8rem',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left',
      display: 'block'
    }
  },
  exportDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  exportForm: {
    display: 'flex',
    justifyContent: 'left',
    gap: 10,
  },
  exportButton: {
    justifyContent: 'end',
  },
  startDate: {
    minWidth: 200,
    maxHeight: 200,
    minHeight: 55,
  },
  endDate: {
    minWidth: 200,
    maxHeight: 200,
    minHeight: 55,
  },
  datesDiv: {
    display: 'flex',
    maxHeight: 150,
    alignItems: 'center',
    gap: 15,
  },
}))

const PreferenceItem = props => {
  const { userPreference, preferencesLoading, doUpdateUserPreference } = props
  return (
    <TableRow style={{ opacity: preferencesLoading ? '60%' : '100%' }}>
      <TableCell width='30%'>
        <Typography>
          {userPreference.preference.name}
        </Typography>
      </TableCell>
      <TableCell width='50%'>
        <Typography>
          {userPreference.preference.description}
        </Typography>
      </TableCell>
      <TableCell width='20%' align='center'>
        <Checkbox
          checked={userPreference.value}
          onChange={async e => {
            const newPrefData = { ...userPreference, value: e.target.checked }
            await doUpdateUserPreference(newPrefData)
          }}
          disabled={preferencesLoading}
        />
      </TableCell>
    </TableRow>
  )
}

export const ExportDataComponent = props => {
  const { classes, doExportData, userId, centers, organizations } = props
  const [selected, setSelected] = useState([])
  const options = centers.map(center => (
    { value: center.id, displayValue: center.name }
  ))
  const organizationButtonsData = organizations.map(org => {
    const orgCenters = centers.filter(center => center.organization === org.id).map(center => center.id)
    return { centers: orgCenters, value: org.id, displayValue: org.name }
  })

  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      user: userId,
      grouped: false,
    },
    onSubmit: async values => {
      const data = {
        ...values,
        centers: selected,
      }
      const success = await doExportData(data)
      if (success) {
        formik.resetForm()
        setSelected([])
      }
    },
    validationSchema: Yup.object({
      startDate: Yup.string().required('Campo requerido'),
      endDate: Yup.string().required('Campo requerido'),
    }),
    validateOnMount: true,
  })
  useEffect(() => {
    return () => formik.resetForm()
  }, [])
  return (
    <div className={classes.exportSection}>
      <div className={classes.exportTitle}>
        <T.H6>Exportar datos</T.H6>
        <FormControlLabel
          control={
            <Checkbox name='grouped' value={formik.values.grouped} onChange={formik.handleChange} />
          }
          label='Agrupar por organización'
        />
      </div>
      <Divider />
      <div className={classes.exportHeader}>
        <Box className={classes.box}>
          <Button
            key='none'
            onClick={() => {
              setSelected([])
            }}
            variant='outlined'
            color='secondary'
            className={classes.orgButton}
          >
            Limpiar
          </Button>
        </Box>
        <Box className={classes.box}>
          <Button
            key='all'
            onClick={() => {
              setSelected(centers.map(c => c.id))
            }}
            variant='outlined'
            color='notice'
            className={classes.orgButton}
          >
            Todos
          </Button>
        </Box>
        {organizationButtonsData.map(orgData => (
          <Box key={orgData.value} className={classes.box}>
            <Button
              key={orgData.value}
              onClick={() => {
                setSelected(orgData.centers)
              }}
              variant='outlined'
              color='notice'
              className={classes.orgButton}
            >
              {orgData.displayValue}
            </Button>
          </Box>
        ))}
      </div>
      <form className={classes.exportDiv} onSubmit={formik.handleSubmit}>
        <div className={classes.exportForm}>
          <MultipleSelectChip
            selected={selected}
            setSelected={setSelected}
            options={options}
            label='Centros'
          />
          <div className={classes.datesDiv}>
            <input
              type='date'
              placeholder='Inicio'
              value={formik.values.startDate}
              onChange={formik.handleChange}
              name='startDate'
              className={classes.startDate}
            />
            <input
              type='date'
              placeholder='Final'
              value={formik.values.endDate}
              onChange={formik.handleChange}
              name='endDate'
              className={classes.endDate}
            />
          </div>
        </div>
        <div className={classes.exportButton}>
          <Button
            disabled={formik.isSubmitting || !formik.isValid}
            type='submit'
            color='secondary'
            variant='outlined'
          >
            Exportar
          </Button>
        </div>
      </form>
    </div>
  )
}

const PreferencesComponent = props => {
  const {
    userPreferences = [],
    preferencesOpen,
    preferencesLoading,
    kind,
    doClosePreferences,
    doUpdateUserPreference,
  } = props
  const classes = useStyles()
  return (
    <Dialog
      open={preferencesOpen}
      onClose={() => {
        doClosePreferences()
      }}
      maxWidth='lg'
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
          backgroundColor: 'white',
          minHeight: '60vh',
        }
      }}
    >
      <DialogTitle>
        <T.H4>Preferencias</T.H4>
      </DialogTitle>
      <DialogContent className={classes.root}>
        <TableContainer className={classes.table}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell width='30%'>
                  <Typography>
                    Nombre
                  </Typography>
                </TableCell>
                <TableCell width='50%'>
                  <Typography>
                    Descripción
                  </Typography>
                </TableCell>
                <TableCell width='20%' />
              </TableRow>
            </TableHead>
            <TableBody>
              {userPreferences.map(userPreference => (
                <PreferenceItem
                  key={userPreference.id}
                  userPreference={userPreference}
                  doUpdateUserPreference={doUpdateUserPreference}
                  preferencesLoading={preferencesLoading}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {kind <= 1 && <ExportDataComponent classes={classes} {...props} />}
      </DialogContent>
    </Dialog>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectUserPreferences',
    'selectPreferencesOpen',
    'selectOrganizations',
    'selectOrganizationsById',
    'selectPreferencesLoading',
    'selectKind',
    'selectUserId',
    'selectCenters',
    'doClosePreferences',
    'doUpdateUserPreference',
    'doExportData',
  )
  return <PreferencesComponent {...props} {...connectedProps} />
}
