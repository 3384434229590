import React, { useState } from 'react'
import Drawer from '@mui/material/Drawer'
import { useConnect } from 'redux-bundler-hook'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SearchBar from '../components/common/SearchBar'
import Conversations from '../components/Messaging/Conversations'
import ChatWindow from '../components/Messaging/ChatWindow'
import AddIcon from '@mui/icons-material/Add'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import UserAvatar from '../components/Messaging/UserAvatar'

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateAreas: '"chats chat"',
    gridTemplateColumns: '20vw 50vw',
    gridTemplateRows: '100%',
    minWidth: '70vw',
    maxHeight: '100vw',
  },
  chats: {
    display: 'flex',
    padding: 10,
    gridArea: 'chats',
    flexDirection: 'column',
    gap: 10,
    height: '100vh',
    maxHeight: '100vh',
    minHeight: '100vh',
    borderRight: 'solid #ededed 1px',
  },
  chat: {
    display: 'flex',
    gridArea: 'chat',
    flexDirection: 'column',
    height: '100vh',
    minHeight: '100vh',
    width: '100%',
    maxWidth: '100%',
  },
  chatHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: 'solid #ededed 1px',
    height: '60px',
    minHeight: '60px',
    maxHeight: '60px',
  },
  avatarDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    marginLeft: 10,
    gap: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  search: {
    display: 'flex',
  },
  chatMessages: {
    backgroundColor: '#F7F7F7',
    height: '100%',
  },
  chatsHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const MessageDrawer = props => {
  const {
    userId,
    kind,
    conversationsOpen,
    conversations: conversationsData,
    currentMessages,
    validNewConversationUsers,
    conversationsSearchValue,
    currentConversation,
    doSetConversationsSearchValue,
    doCloseConversations,
    doSetSelectedConversation,
    doPostMessageToConversation,
    doMarkAsReadBulk,
    doAddConversation,
  } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleNewConvWithUser = async (e) => {
    const { userId: withUserId } = e.currentTarget.dataset
    const data = {
      users: [userId, parseInt(withUserId, 10)],
      description: '',
    }
    await doAddConversation(data)
    setAnchorEl(null)
  }

  return (
    <Drawer
      BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.1 } }}
      anchor='left'
      open={conversationsOpen}
      onClose={doCloseConversations}
      elevation={5}
    >
      <div className={classes.root}>
        <div className={classes.chats}>
          <div className={classes.chatsHeader}>
            <Typography
              fontSize='2rem'
              fontFamily='roboto'
              fontWeight={500}
            >
              Chats
            </Typography>
            {(validNewConversationUsers && kind < 2) && (
              <Tooltip placement='top' arrow title='Nuevo chat'>
                <IconButton
                  aria-controls={open ? 'new-chat-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              id='new-chat-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {validNewConversationUsers ? (
                validNewConversationUsers?.map(user => (
                  <MenuItem
                    data-user-id={user.id}
                    key={user.id}
                    onClick={handleNewConvWithUser}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 10,
                      }}
                    >
                      <UserAvatar user={user} />
                      <Typography fontSize={12} fontFamily='roboto'>{user.fullName || user.username}</Typography>
                    </div>
                  </MenuItem>
                ))
              ) : <Typography fontFamily='roboto'>-</Typography>}
            </Menu>
          </div>
          <div className={classes.search}>
            <SearchBar
              searchValue={conversationsSearchValue}
              setSearchValue={doSetConversationsSearchValue}
              debounceTime={600}
              placeholder='Buscar en chats...'
            />
          </div>
          <div>
            <Conversations
              conversationsData={conversationsData}
              userId={userId}
              doSetSelectedConversation={doSetSelectedConversation}
              currentConversation={currentConversation}
            />
          </div>
        </div>
        <div className={classes.chat}>
          <ChatWindow
            userId={userId}
            currentConversation={currentConversation}
            currentMessages={currentMessages}
            doCloseConversations={doCloseConversations}
            doPostMessageToConversation={doPostMessageToConversation}
            doMarkAsReadBulk={doMarkAsReadBulk}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectConversationsOpen',
    'selectConversationsSearchValue',
    'selectValidNewConversationUsers',
    'selectConversations',
    'selectCurrentConversation',
    'selectCurrentConversationId',
    'selectUserId',
    'selectKind',
    'selectCurrentMessages',
    'doSetConversationsSearchValue',
    'doCloseConversations',
    'doSetSelectedConversation',
    'doPostMessageToConversation',
    'doMarkAsReadBulk',
    'doAddConversation',
  )
  return <MessageDrawer {...connectedProps} {...props} />
}
