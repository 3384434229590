import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Document, Page } from 'react-pdf'
import { useConnect } from 'redux-bundler-hook'
import Chip from '@mui/material/Chip'
import Pagination from '@mui/material/Pagination'
import Checkbox from './common/Checkbox'

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateAreas: [
      '"items preview"',
    ].join('\n'),
    gridTemplateColumns: '2fr 5fr',
    minHeight: 600,
    gap: 10,
    maxHeight: 600,
  },
  items: {
    gridArea: 'items',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    overflowY: 'auto',
    maxHeight: 600,
  },
  itemPagination: {
    gridArea: 'pagination',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1,
  },
  preview: {
    gridArea: 'preview',
    overflowY: 'auto',
    maxHeight: 600,
    border: '1px solid #ebebeb',
    borderRadius: 10,
    boxShadow: '0px 0px 8px 0px #ebebeb',
  },
  elementDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #c4c4c4',
    borderRadius: 10,
    padding: 10,
    '&:hover': {
      backgroundColor: '#f0f0f0',
      cursor: 'pointer',
    },
    gap: 5,
  },
  selectedElementDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '2px solid #2196f3',
    borderRadius: 10,
    padding: 10,
    backgroundColor: '#f0f0f0',
    gap: 5,
  },
}))

const TraceDocumentComponent = props => {
  const {
    traceDocument,
    classes,
    onClick,
    selectedDoc,
    isEcgAssigned = false,
  } = props
  return (
    <div
      className={
        // TODO: refactor this wtf was i thinking
        isEcgAssigned && !selectedDoc
          ? classes.selectedElementDiv
          : selectedDoc?.id === traceDocument?.id
            ? classes.selectedElementDiv
            : classes.elementDiv
      }
      onClick={() => onClick(traceDocument)}
    >
      {isEcgAssigned && (
        <Typography fontSize={14} fontWeight='bold' sx={{ color: '#1769aa' }}>ASIGNADO</Typography>
      )}
      <Tooltip title={<Typography fontFamily='monospace' fontSize={11}>{traceDocument?.name}</Typography>} arrow placement='bottom'>
        <Typography
          fontFamily='monospace'
          fontSize={12}
          noWrap
          sx={{
            padding: '5px',
            border: '1px solid #9CA7B2',
            borderRadius: 5,
            width: '100%',
          }}>
          {/* {stringToMaxLength(traceDocument.name, 45)} */}
          {traceDocument?.name}
        </Typography>
      </Tooltip>
      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
        <Chip size='small' label={`ID: ${traceDocument?.id}`} variant='outlined' color='secondary' style={{ fontFamily: 'monospace' }} />
        <Typography fontFamily='monospace' fontSize={12}>Centro: {traceDocument?.center?.name ?? 'N/A'}</Typography>
      </div>
      <Typography fontFamily='monospace' fontSize={12}>{new Date(traceDocument.createdOn).toLocaleString()}</Typography>
      <div style={{ display: 'flex', gap: 5 }}>
        <Chip
          label={traceDocument?.ecgPtr ? `ECG ID: ${traceDocument?.ecgPtr}` : 'Sin asignar'}
          color={traceDocument?.ecgPtr ? 'info' : 'warning'}
          variant='outlined'
          size='small'
          sx={{
            width: '40%',
            fontFamily: 'monospace',
            fontSize: 12
          }}
        />
        {traceDocument?.metadata?.deviceId && (
          <Chip
            label={traceDocument?.metadata?.deviceId}
            color='info'
            size='small'
            variant='outlined'
            sx={{
              fontFamily: 'monospace',
              width: '30%',
              fontSize: 12
            }}
          />
        )}
      </div>
    </div>
  )
}

const AssignTraceTable = props => {
  const {
    assignTracesTableRaw,
    assignTracesTableSelectedItem,
    assignTracesFilterParams,
    doSetAssignTracesTableSelectedItem,
    doSetAssignTracesTablePage,
    doSetAssignTracesFilterParams,
    selectedEcg = null,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        {!!selectedEcg && selectedEcg?.assignedTrace && (
          <TraceDocumentComponent
            key='selectedEcgDocument'
            traceDocument={selectedEcg?.assignedTrace}
            classes={classes}
            onClick={doc => doSetAssignTracesTableSelectedItem(doc)}
            selectedDoc={assignTracesTableSelectedItem}
            isEcgAssigned
          />
        )}
        <div className={classes.itemPagination}>
          <Pagination
            count={assignTracesTableRaw.totalPages}
            variant='outlined'
            shape='rounded'
            color='primary'
            onChange={(event, value) => doSetAssignTracesTablePage(value)}
          />
          <Tooltip placement='top' arrow title='Incluye los trazos asignados.'>
            <Checkbox
              checked={assignTracesFilterParams?.all}
              label='Todos'
              onChange={e => {
                const checked = e.target.checked
                if (checked) {
                  doSetAssignTracesFilterParams({ all: true })
                } else {
                  doSetAssignTracesFilterParams({})
                }
              }}
            />
          </Tooltip>
        </div>
        {assignTracesTableRaw.items.map(item => (
          <TraceDocumentComponent
            key={item.id}
            traceDocument={item}
            classes={classes}
            onClick={doc => doSetAssignTracesTableSelectedItem(doc)}
            selectedDoc={assignTracesTableSelectedItem}
          />
        ))}
      </div>
      <div className={classes.preview}>
        <Document file={assignTracesTableSelectedItem?.filePtr}>
          <Page
            pageNumber={1}
            height={700}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectAssignTracesTableRaw',
    'selectAssignTracesFilterParams',
    'selectAssignTracesTableSelectedItem',
    'doSetAssignTracesTableSelectedItem',
    'doSetAssignTracesTablePage',
    'doSetAssignTracesFilterParams',
  )

  return <AssignTraceTable {...props} {...connectedProps} />
}
