import axios from 'axios'
import { createSelector } from 'redux-bundler'
import { tokenHeader, tokenHeaderFile } from '../utils'

export const types = {
  SET_ECGS_INITIAL_STATE: 'SET_ECGS_INITIAL_STATE',
  FETCH_ECGS_START: 'FETCH_ECGS_START',
  FETCH_ECGS_FINISHED: 'FETCH_ECGS_FINISHED',
  FETCH_ECGS_ERROR: 'FETCH_ECGS_ERROR',
  GET_ECGS: 'GET_ECGS',
  UPDATE_ECG_DATA: 'UPDATE_ECG_DATA',
  ADD_ECG: 'ADD_ECG',
  DELETE_ECG: 'DELETE_ECG',
  EDIT_ECG: 'EDIT_ECG',
  SAVE_ECG: 'SAVE_ECG',
  UPLOAD_PDF: 'UPLOAD_PDF',
  RED_CODE: 'RED_CODE',
  REJECT_ECG: 'REJECT_ECG',
  ACCEPT_ECG: 'ACCEPT_ECG',
  OPEN_UPLOAD: 'OPEN_UPLOAD',
  CLOSE_UPLOAD: 'CLOSE_UPLOAD',
  SET_ECG_SELECTED: 'SET_ECG_SELECTED',
  CLEAR_ECG_SELECTED: 'CLEAR_ECG_SELECTED',
  SET_ECG_SEARCH_VALUE: 'SET_ECG_SEARCH_VALUE',
  CLEAR_ECG_SEARCH_VALUE: 'CLEAR_ECG_SEARCH_VALUE',
  SET_CACHED_ECGS: 'SET_CACHED_ECGS',
  CLEAR_CACHED_ECGS: 'CLEAR_CACHED_ECGS',
  SET_ECGS_PAGE_SIZE: 'SET_ECGS_PAGE_SIZE',
  SET_ECGS_PAGE_NUMBER: 'SET_ECGS_PAGE_NUMBER',
  SET_ECG_AS_READ: 'SET_ECG_AS_READ',
  SET_ECG_AS_UNREAD: 'SET_ECG_AS_UNREAD',
  SET_ECGS_LOADING: 'SET_ECGS_LOADING',
  SET_ECGS_IDLE: 'SET_ECGS_IDLE',
  EXPORT_DATA_START: 'EXPORT_DATA_START',
  EXPORT_DATA_SUCCES: 'EXPORT_DATA_SUCCESS',
  EXPORT_DATA_ERROR: 'EXPORT_DATA_ERROR',
}

const initialState = {
  next: null,
  previous: null,
  currentPage: 1,
  total: null,
  perPage: null,
  totalPages: null,
  entities: [],
  loading: false,
  lastFetch: null,
  lastError: null,
  openECGDialog: false,
  selectedECG: null,
  searchValue: '',
  cachedEntities: {},
  pageSize: 20,
}

export default {
  name: 'ecgs',
  reducer: (state = initialState, action) => {
    let index = null
    let newArr = null
    let entitiesBuffer = {}
    let unsavedBuffer = {}
    switch (action.type) {
    case types.GET_ECGS:
      entitiesBuffer = state.cachedEntities
      action.payload.entities?.forEach(ecg => entitiesBuffer[ecg.id] = ecg)
      // localStorage.setItem('cachedEcgs', JSON.stringify(entitiesBuffer))
      return {
        ...state,
        ...action.payload,
        lastFetch: Date.now(),
        // cachedEntities: entitiesBuffer,
      }
    case types.ADD_ECG:
      entitiesBuffer = state.cachedEntities
      entitiesBuffer[action.payload.id] = action.payload
      // localStorage.setItem('cachedEcgs', JSON.stringify(entitiesBuffer))
      return {
        ...state,
        // cachedEntities: entitiesBuffer,
      }
    case types.RED_CODE:
    case types.REJECT_ECG:
    case types.ACCEPT_ECG:
    case types.EDIT_ECG:
      index = state.entities.findIndex(ecg => ecg.id === action.payload.id)
      entitiesBuffer = state.cachedEntities
      entitiesBuffer[action.payload.id] = action.payload
      // localStorage.setItem('cachedEcgs', JSON.stringify(entitiesBuffer))
      if (index === -1) {
        return {
          ...state,
          // cachedEntities: entitiesBuffer
        }
      }
      newArr = [...state.entities]
      newArr[index] = action.payload

      return {
        ...state,
        entitites: newArr,
        // cachedEntities: entitiesBuffer,
      }
    case types.SAVE_ECG:
      index = state.entities.findIndex(ecg => ecg.id === action.payload.id)
      entitiesBuffer = state.cachedEntities
      entitiesBuffer[action.payload.id] = action.payload
      // localStorage.setItem('cachedEcgs', JSON.stringify(entitiesBuffer))
      // Only delete ecg data from unsavedEcgs if the user hits the save button explicitly
      unsavedBuffer = localStorage.getItem('unsavedEcgs') ?? '{}'
      unsavedBuffer = JSON.parse(unsavedBuffer)
      delete unsavedBuffer[action.payload.id]
      localStorage.setItem('unsavedEcgs', JSON.stringify(unsavedBuffer))
      if (index === -1) {
        return {
          ...state,
          // cachedEntities: entitiesBuffer
        }
      }
      newArr = [...state.entities]
      newArr[index] = action.payload

      return {
        ...state,
        entitites: newArr,
        // cachedEntities: entitiesBuffer,
      }
    case types.DELETE_ECG:
      entitiesBuffer = state.cachedEntities
      delete entitiesBuffer[action.payload]
      newArr = [...state.entities]
      newArr = newArr.filter(e => e.id !== action.payload)
      // localStorage.setItem('cachedEcgs', JSON.stringify(entitiesBuffer))
      return {
        ...state,
        entitites: newArr,
        // cachedEntities: entitiesBuffer,
      }
    case types.FETCH_ECGS_START:
      return {
        ...state,
        loading: true,
      }
    case types.SET_ECGS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case types.SET_ECGS_IDLE:
      return {
        ...state,
        loading: false,
      }
    case types.SET_ECGS_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
        currentPage: 1,
      }
    case types.SET_ECGS_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.payload,
      }
    case types.FETCH_ECGS_FINISHED:
      return {
        ...state,
        loading: false,
        lastError: null,
      }
    case types.FETCH_ECGS_ERROR:
      return {
        ...state,
        lastError: Date.now(),
        loading: false,
      }
    case types.OPEN_UPLOAD:
      return {
        ...state,
        openECGDialog: true,
        selectedECG: action.payload,
      }
    case types.CLOSE_UPLOAD:
      return {
        ...state,
        openECGDialog: false,
        selectedECG: null,
      }
    case types.SET_ECG_SELECTED:
      return {
        ...state,
        selectedECG: action.payload,
      }
    case types.CLEAR_ECG_SELECTED:
      return {
        ...state,
        selectedECG: null,
      }
    case types.SET_ECG_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      }
    case types.CLEAR_ECG_SEARCH_VALUE:
      return {
        ...state,
        searchValue: '',
      }
    case types.SET_CACHED_ECGS:
      return {
        ...state,
        // cachedEntities: action.payload,
      }
    case types.CLEAR_CACHED_ECGS:
      // localStorage.setItem('cachedEcgs', JSON.stringify({}))
      return {
        ...state,
        // cachedEntities: {},
      }
    case types.SET_ECGS_INITIAL_STATE:
      return {
        ...initialState,
      }
    default:
      return state
    }
  },
  selectEcgs: state => state.ecgs.entities,
  selectEcgsLoading: state => state.ecgs.loading,
  selectEcgSearchValue: state => state.ecgs.searchValue,
  selectEcgsRaw: state => state.ecgs,
  selectCachedEcgs: state => state.ecgs.cachedEntities,
  selectEcgsCurrentPage: state => state.ecgs.currentPage,
  selectEcgsPageSize: state => state.ecgs.pageSize,
  selectCanUpdateWs: createSelector(
    'selectEcgSearchValue',
    'selectKind',
    'selectFilterValue',
    (ecgSearchValue, kind, filterValue) => {
      if (ecgSearchValue !== '') return false
      if (kind === 2) {
        if (filterValue !== 'inProgress') return false
      } else {
        if (filterValue !== 'all') return false
      }
      // TODO: think about conditioning if the page is not the first
      return true
    },
  ),
  doSetEcgsLoading: () => ({ dispatch }) => dispatch({ type: types.SET_ECGS_LOADING }),
  doSetEcgsIdle: () => ({ dispatch }) => dispatch({ type: types.SET_ECGS_IDLE }),
  doSetSelectedECG: id => ({ dispatch }) => dispatch({ type: types.SET_ECG_SELECTED, payload: id }),
  doClearSelectedECG: () => ({ dispatch }) => dispatch({ type: types.SET_ECG_SELECTED }),
  doSetEcgsInitialState: () => ({ dispatch }) => dispatch({ type: types.SET_ECGS_INITIAL_STATE }),
  doMarkEcgAsRead: ecg => async ({ dispatch, store }) => {
    const access = store.selectAccessToken()
    const isRead = ecg?.isRead ?? false
    if (isRead) return
    let res
    try {
      res = await axios.post(`/api/ecg/${ecg.id}/markAsRead/`, {}, tokenHeader(access))
      dispatch({
        type: types.EDIT_ECG,
        payload: res.data,
      })
      store.doSetSelectedEcg(res.data)
      dispatch({ type: types.SET_ECG_AS_READ })
      return true
    } catch (error) {
      dispatch({ type: types.SET_ECGS_IDLE })
      console.error(error.response.data)
    }
  },
  doMarkEcgAsUnread: ecg => async ({ dispatch, store }) => {
    dispatch({ type: types.SET_ECG_AS_UNREAD })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post(`/api/ecg/${ecg.id}/markAsUnread/`, {}, tokenHeader(access))
      dispatch({
        type: types.EDIT_ECG,
        payload: res.data,
      })
      store.doSetSelectedEcg(res.data)
      dispatch({ type: types.SET_ECGS_IDLE })
      return true
    } catch (error) {
      console.error(error.response.data)
      dispatch({ type: types.SET_ECGS_IDLE })
    }
  },
  doSetSearchValue: value => async ({ dispatch, store }) => {
    await dispatch({ type: types.SET_ECG_SEARCH_VALUE, payload: value })
    await store.doFetchECGS()
  },
  doClearSearchValue: () => ({ dispatch }) => dispatch({ type: types.CLEAR_ECG_SEARCH_VALUE }),
  doSetEcgsCurrentPage: page => async ({ dispatch, store }) => {
    dispatch({ type: types.SET_ECGS_PAGE_NUMBER, payload: page })
    store.doSetAppLoading()
    await store.doFetchECGS()
    store.doSetAppIdle()
  },
  doSetEcgsPageSize: size => async ({ dispatch, store }) => {
    dispatch({ type: types.SET_ECGS_PAGE_SIZE, payload: size })
    store.doSetAppLoading()
    await store.doFetchECGS()
    store.doSetAppIdle()
  },
  doFetchECGS: () => async ({ dispatch, store }) => {
    dispatch({ type: types.FETCH_ECGS_START })
    const access = store.selectAccessToken()
    const search = store.selectEcgSearchValue()
    const filterParams = store.selectFilterParams()
    const page = store.selectEcgsCurrentPage()
    const pageSize = store.selectEcgsPageSize()
    let res
    const opts = tokenHeader(access)
    const params = {
      page,
      pageSize,
      search,
      ...filterParams,
    }
    opts.params = params
    try {
      res = await axios.get('/api/ecg/', opts)
      dispatch({
        type: types.GET_ECGS,
        payload: res.data,
      })
      dispatch({ type: types.FETCH_ECGS_FINISHED })
      dispatch({ type: types.SET_ECGS_IDLE })
      store.doSetAppIdle()
    } catch (error) {
      dispatch({ type: types.FETCH_ECGS_ERROR })
      dispatch({ type: types.SET_ECGS_IDLE })
      store.doSetAppIdle()
      throw error
    }
    store.doSetAppIdle()
  },
  doUpdateECGData: ecg => async ({ dispatch }) => dispatch({ type: types.EDIT_ECG, payload: ecg }),
  doAddECG: ecg => async ({ store }) => {
    const access = store.selectAccessToken()
    try {
      store.doSetAppLoading()
      const res = await axios.post('/api/ecg/', ecg, tokenHeader(access))
      store.dispatch({
        type: types.ADD_ECG,
        payload: res.data,
      })
      store.dispatch({
        type: 'API_SUCCESS',
        payload: `ECG creado: ${res.data.patient.name}`,
      })
      store.doSetAppIdle()
      store.doFetchECGS()
      return true
    } catch (err) {
      store.doSetAppIdle()
      const rawErrors = err?.response?.data ?? []
      let errors = ''
      Object.entries(rawErrors).forEach((e) => {
        const [key, values] = e
        const value = values.flat(3)
        errors += `${key}: ${String(value)}\n`
      })

      errors = errors
        .replace('studioId', 'Folio')
        .replace('height', 'Altura')
        .replace('weight', 'Peso')
        .replace('bloodPressure', 'Presion arterial')
        .replace('lastEcgDate', 'Fecha de último estudio')

      store.doSetAppIdle()
      store.doSetSnackbarFail(errors)
      store.doSetAppIdle()
      return false
    }
  },
  doEditECG: (ecg, id) => async ({ dispatch, store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    const idVal = id === undefined ? ecg.id : id
    let res
    try {
      res = await axios.patch(`/api/ecg/${idVal}/`, ecg, tokenHeader(access))
      dispatch({
        type: types.SAVE_ECG,
        payload: res.data,
      })
      store.doSetAppIdle()
      store.doSetSnackbarSuccess('Guardado correctamente')
      await store.doFetchECGS()
      const { unsaved, open } = store.selectEcgDialogRaw()
      if (unsaved && open) store.doSetEcgDialogUnsaved(false)
      return res.data
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail('Error al guardar ECG')
      return false
    }
  },
  doSetECGRedCode: id => async ({ dispatch, store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post(`/api/ecg/${id}/redCode/`, null, tokenHeader(access))
      dispatch({
        type: types.RED_CODE,
        payload: res.data,
      })
      store.doSetAppIdle()
      store.doSetSnackbarSuccess('ECG marcado como código rojo')
      return res.data
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail('Error al marcar código rojo')
      return false
    }
  },
  doRejectECG: id => async ({ dispatch, store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post(`/api/ecg/${id}/reject/`, null, tokenHeader(access))
      dispatch({
        type: types.REJECT_ECG,
        payload: res.data,
      })
      store.doSetAppIdle()
      store.doSetSnackbarInfoCenter('ECG rechazado')
      return res.data
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail('Error al rechazar ECG')
      return false
    }
  },
  doAcceptECG: id => async ({ dispatch, store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post(`/api/ecg/${id}/accept/`, null, tokenHeader(access))
      dispatch({
        type: types.ACCEPT_ECG,
        payload: res.data,
      })
      store.doSetAppIdle()
      store.doSetSnackbarSuccess('ECG aceptado')
      return res.data
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail('Error al aceptar ECG')
      return false
    }
  },
  doSoftDeleteECG: id => async ({ dispatch, store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    try {
      await axios.delete(`/api/ecg/${id}/`, tokenHeader(access))
      dispatch({
        type: types.DELETE_ECG,
        payload: id,
      })
      store.doSetAppIdle()
      store.doSetSnackbarInfo('ECG eliminado')
      return true
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail('Error al eliminar ECG')
      return false
    }
  },
  doUploadPDF: (pdf, id) => async ({ dispatch, store }) => {
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post(`/api/ecg/${id}/ocr/`, pdf, tokenHeaderFile(access))
      dispatch({
        type: types.EDIT_ECG,
        payload: res.data,
      })
      store.doSetSnackbarSuccess('Trazo cargado correctamente')
      store.doSetAppIdle()
      return true
    } catch (error) {
      if (error.response.status === 403) {
        store.doSetSnackbarFail(error.response.data.detail)
        return
      }
      if (error.response.status === 400) {
        store.doSetSnackbarFail(error.response.data.error)
        return
      }
      store.doSetSnackbarFail('Error al subir el archivo. Verifique si es valido o contacte a soporte.')
      store.doSetAppIdle()
    }
  },
  doUploadEcgDocument: (file, id, docNumber = '') => async ({ store }) => {
    const fileDialogOpen = store.selectFileDialogOpen()
    if (!fileDialogOpen) store.doSetAppLoading()
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post(`/api/ecg/${id}/uploadToFiles/`, file, tokenHeaderFile(access))
      store.doSetAppIdle()
      store.doSetSnackbarSuccess(`Documento cargado correctamente. ${docNumber}`)
      store.doUpdateECGData(res.data)
      await store.doFetchECGS()
      return res.data
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail('Error al subir el archivo. Verifique si es valido o contacte a soporte.')
    }
  },
  doGenerateReport: id => async ({ store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get(`/api/ecg/${id}/generateReport/`, tokenHeader(access))
      const { report } = res.data
      store.doSetAppIdle()
      window.open(report, '_blank')
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail('Error al generar reporte')
    }
  },
  doExportData: params => async ({ store, dispatch }) => {
    // params should contain center (number), startDate (ISODateStr), endDate (ISODateStr)
    dispatch({ type: types.EXPORT_DATA_START })
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post('/api/ecg/exportData/', params, tokenHeader(access))
      dispatch({ type: types.EXPORT_DATA_SUCCES })
      const { message } = res.data
      store.doSetSnackbarSuccess(message)
      store.doSetAppIdle()
      return true
    } catch (err) {
      const error = err?.response?.data?.error
      let errorMsg = error ? String(error) : 'Error descononcido.'
      if (Array.isArray(error)) {
        errorMsg = error.join('. ')
      }
      dispatch({ type: types.EXPORT_DATA_ERROR })
      store.doSetSnackbarFail(errorMsg)
      store.doSetAppIdle()
      return false
    }
  },
  doSetCachedEcgs: cached => ({ dispatch }) => dispatch({ type: types.SET_CACHED_ECGS, payload: cached }),
  doClearCachedEcgs: () => ({ dispatch }) => dispatch({ type: types.CLEAR_CACHED_ECGS }),
  doAddFile: (file, id) => async ({ dispatch, store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post(`/api/ecg/${id}/uploadToFiles/`, file, tokenHeaderFile(access))
      dispatch({
        type: types.EDIT_ECG,
        payload: res.data,
      })
      store.doSetSelectedEcg(res.data)
      store.doSetAppIdle()
      store.doSetSnackbarSuccess('Archivo cargado correctamente')
      return true
    } catch (error) {
      store.doSetAppIdle()
      if (error.response.status === 403) {
        store.doSetSnackbarFail(error.response.data.detail)
        return
      }
      store.doSetSnackbarFail('Error al subir el archivo. Verifique si es valido o contacte a soporte.')
    }
  },
  doSendEcgReportEmail: id => async ({ store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    try {
      await axios.get(`/api/ecg/${id}/emailReport/`, tokenHeaderFile(access))
      store.doSetAppIdle()
      store.doSetSnackbarSuccess('Reporte enviado por email.')
      return true
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail(error.response.data.error)
    }
  },
  doDeleteECGDocument: id => async ({ store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    try {
      await axios.delete(`/api/documents/${id}/`, tokenHeader(access))
      await store.doFetchEcgDialogData(null, true)
      store.doSetAppIdle()
      store.doSetSnackbarSuccess('Archivo eliminado.')
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail('Error al eliminar archivo.')
    }
  },
  doAssignTraceToEcg: (document, ecgId) => async ({ store }) => {
    store.doSetAppLoading()
    if (!document) {
      store.doSetAppIdle()
      store.doSetSnackbarFail('Debe seleccionar un trazo.')
      return false
    }
    if (!ecgId) {
      store.doSetAppIdle()
      store.doSetSnackbarFail('Debe seleccionar un estudio.')
      return false
    }
    const access = store.selectAccessToken()
    try {
      await axios.post(`/api/ecg/${ecgId}/setAssignedTrace/`, { document }, tokenHeader(access))
      store.doSetAppIdle()
      store.doSetSnackbarSuccess('Trazo asignado correctamente.')
      await store.doFetchECGS()
      await store.doFetchAssignTracesTableList()
      return true
    } catch (error) {
      store.doSetAppIdle()
      if (error?.response?.data?.error) {
        store.doSetSnackbarFail(error.response.data.error)
        return false
      }
      store.doSetSnackbarFail('Error al asignar trazo.')
    }
  },
  doSendOcrErrorReport: (id, message) => async ({ store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    try {
      await axios.post(`/api/ecg/${id}/reportOcrError/`, { message }, tokenHeader(access))
      store.doSetAppIdle()
      store.doSetSnackbarSuccess('Reporte enviado.')
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail(error?.response?.data?.error ?? 'Error al enviar reporte.')
    }
  },
  doGetPublicReportQr: id => async ({ store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    try {
      const res = await axios.get(`/api/ecg/${id}/public/report/qr/`, tokenHeader(access))
      const url = res.data.url
      window.open(url, '_blank')
      store.doSetAppIdle()
      return true
    } catch (error) {
      store.doSetAppIdle()
      store.doSetSnackbarFail(error?.response?.data?.error ?? 'Error al obtener QR. Contacte a soporte.')
    }
  },
  reactShouldFetchECGs: createSelector(
    'selectEcgsRaw',
    'selectIsAuthenticated',
    'selectAppTime',
    'selectIsOnline',
    (ecgsRaw, isAuthenticated, appTime, isOnline) => {
      if (!isAuthenticated) return
      if (ecgsRaw.loading) return
      if (!isOnline) return
      let shouldFetch = false
      let timePassedLastFetch = null
      // if there are no entities, fetch
      if (ecgsRaw.lastFetch) {
        timePassedLastFetch = appTime - ecgsRaw.lastFetch
        if (ecgsRaw.entities.length === 0 && timePassedLastFetch > 60000) shouldFetch = true
        if (timePassedLastFetch > 60000 * 5) shouldFetch = true
      } else if (!ecgsRaw.entities.length) shouldFetch = true
      // if an error ocurred, wait 5s and retry
      if (ecgsRaw.lastError) {
        const timePassed = appTime - ecgsRaw.lastError
        if (timePassed > 10000) {
          shouldFetch = true
        }
      }
      if (shouldFetch) return { actionCreator: 'doFetchECGS' }
    }
  ),
  init: store => {
    // const cachedLocalStorage = localStorage.getItem('cachedEcgs')
    // if (cachedLocalStorage === null) return
    // const cachedEntities = JSON.parse(cachedLocalStorage)
    // store.doSetCachedEcgs(cachedEntities)
    const isAuth = store.selectIsAuthenticated()
    if (isAuth) store.doFetchECGS()
  }
}
