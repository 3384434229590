import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Viewer from '../../common/Viewer'
import History from './History'
import FileIcon from '../../common/FileIcon'
import PatientData from './PatientData'
import Spinner from '../../common/Spinner'
import actions from '../../../utils/actions'

const FONT_SIZE = '0.9rem'
const MIN_WIDTH = 110
const MIN_HEIGHT = 40

const TAB_WIDTH = 120
const TAB_HEIGHT = 80

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateAreas: [
      '"tabs"',
      '"content"',
    ].join('\n'),
    height: '100%',
    gridTemplateRows: 'auto 1fr',
    width: '100%',
  },
  tabs: {
    display: 'flex',
    gridArea: 'tabs',
    overflowX: 'scroll',
    height: '100%',
    minHeight: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'content',
    overflowY: 'scroll',
    height: '100%',
    width: '100%',
    minHeight: '85vh',
    maxHeight: '85vh',
  },
  spinnerDiv: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  filesDiv: {
    display: 'flex',
    padding: 10,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    width: '100%',
    height: '100%',
    maxHeight: '85vh',
    overflowY: 'scroll',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 20,
    alignItems: 'left',
    padding: '20px 12px',
  },
  tabsComponent: {
    height: TAB_HEIGHT,
    minHeight: TAB_HEIGHT,
  },
  tab: {
    height: TAB_HEIGHT,
    minHeight: TAB_HEIGHT,
    minWidth: TAB_WIDTH,
    width: TAB_WIDTH,
  },
}))

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const TabPanel = props => {
  const { children, value, index } = props
  return value === index && (
    <div style={{ height: '100%' }}>
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const EditDialog = props => {
  const {
    open,
    onClose,
    ecgData,
    loading,
    pdfKey,
    kind,
    historicEcgs,
    doUpdateUrl,
    doFetchEcgDialogData,
    doAddFile,
    doMarkEcgAsRead,
  } = props
  // const isRead = ecgData?.isRead

  // useEffect(() => {
  //   return async () => {
  //     if (open && ecgData && !loading && !isRead) await doMarkEcgAsRead(ecgData)
  //   }
  // }, [open])

  const [value, setValue] = useState(0)
  const classes = useStyles()
  const validDocuments = ecgData?.documents || []
  return (
    <Dialog
      open={open}
      onClose={async () => {
        doUpdateUrl('/')
        onClose()
        await doMarkEcgAsRead(ecgData)
        setValue(0)
      }}
      maxWidth='xl'
      PaperProps={{
        style: {
          borderRadius: 20,
          backgroundColor: 'white',
          minHeight: '90vh',
        }
      }}
      fullWidth
    >
      <div className={classes.root}>
        <div name='tabs' className={classes.tabs}>
          <Tabs
            value={value}
            onChange={(_, newVal) => setValue(newVal)}
            indicatorColor='primary'
            textColor='inherit'
            aria-label='full width tabs example'
            variant='scrollable'
            scrollButtons='auto'
            classes={{ root: classes.tabsComponent }}
          >
            <Tab classes={{root: classes.tab}} label='Datos' {...a11yProps(0)} />
            <Tab classes={{root: classes.tab}} label='Trazo' {...a11yProps(1)} />
            <Tab classes={{root: classes.tab}} label='Expediente' {...a11yProps(2)} />
            <Tab classes={{root: classes.tab}} label='Archivos' {...a11yProps(3)} />
          </Tabs>
        </div>
        <div className={classes.content}>
          <TabPanel value={value} index={0}>
            {loading ? (
              <div className={classes.spinnerDiv}>
                <Spinner />
              </div>
            ) : (
              <PatientData ecgData={ecgData} />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Viewer
              apiKey={pdfKey}
              ecgData={ecgData}
              doFetchEcgDialogData={doFetchEcgDialogData}
              kind={kind}
            />
            <div className={classes.col}>
              {ecgData?.ecg !== null &&
                <Button
                  name='trace_link'
                  variant='contained'
                  onClick={() => window.open(ecgData?.ecg, '_blank')}
                  sx={{
                    minWidth: MIN_WIDTH,
                    minHeight: MIN_HEIGHT,
                    backgroundColor: '#486581',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: FONT_SIZE,
                      color: 'white',
                    }}
                  >
                    Trazo
                  </Typography>
                </Button>}
              {ecgData?.confirmedTraces?.length > 0 &&
                <Button
                  name='confirmed_trace_link'
                  variant='contained'
                  onClick={() => actions.onClickViewConfirmedTrace(ecgData)}
                  sx={{
                    minWidth: MIN_WIDTH,
                    minHeight: MIN_HEIGHT,
                    backgroundColor: '#87CA42',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: FONT_SIZE,
                      color: 'white',
                    }}
                  >
                    Trazos confirmados
                  </Typography>
                </Button>}
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <History historicEcgs={historicEcgs} currentId={ecgData?.id} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className={classes.filesDiv}>
              {validDocuments
                ?.sort((a, b) => b.name.startsWith('CONFIRMED__') - a.name.startsWith('CONFIRMED__'))
                ?.map(doc => (
                  <FileIcon key={doc.filename} fileData={doc} size={200} kind={kind} />
                ))}
              {kind > 1 && !validDocuments.length && (<Typography textAlign='center' color='#5c5c5c' fontSize={16}>No hay archivos</Typography>)}
              {kind <= 1 && <FileIcon key='add' isNew size={100} onClick={doAddFile} ecgData={ecgData} />}
            </div>
          </TabPanel>
        </div>
      </div>
    </Dialog>
  )
}

EditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  ecgData: PropTypes.object,
  loading: PropTypes.bool,
}

export default EditDialog
