// import { createSelector } from 'redux-bundler'
// import { replaceStringTemplate } from '../utils/string'

export const types = {
  SET_APP_IDLE: 'SET_APP_IDLE',
  SET_APP_LOADING: 'SET_APP_LOADING',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_TITLE: 'SET_TITLE',
}

const initialState = {
  loading: false,
  isMobile: window.matchMedia('screen and (max-width: 760px)').matches,
  title: 'CIDC',
  lastTitle: 'CIDC',
}

export default {
  name: 'appState',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.SET_APP_IDLE:
      return {
        ...state,
        loading: false,
      }
    case types.SET_APP_LOADING:
      return {
        ...state,
        loading: true,
      }
    case types.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      }
    case types.SET_TITLE:
      return {
        ...state,
        title: action.payload,
        lastTitle: state.title,
      }
    default:
      break
    }
    return state
  },
  selectAppState: state => state.appState,
  selectIsMobile: state => state.appState.isMobile,
  selectAppTitle: state => state.appState.title,
  selectLastAppTitle: state => state.appState.lastTitle,
  doSetAppIdle: () => ({ dispatch }) => dispatch({ type: types.SET_APP_IDLE }),
  doSetAppLoading: () => ({ dispatch }) => dispatch({ type: types.SET_APP_LOADING }),
  doSetIsMobile: isMobile => ({ dispatch }) => dispatch({ type: types.SET_IS_MOBILE, payload: isMobile }),
  doUpdateAppTitle: title => ({ dispatch }) => dispatch({ type: types.SET_TITLE, payload: title }),
  // reactChangePageTitle: createSelector(
  //   'selectRoute',
  //   'selectUser',
  //   'selectRouteParams',
  //   'selectAppTitle',
  //   (route, user, params, currentTitle) => {
  //     const { titleTemplate } = route
  //     const fullName = user?.fullName
  //     const ecgId = params?.ecgId
  //     let replacements = { fullName, ecgId }
  //     const title = replaceStringTemplate(titleTemplate, replacements)
  //     if (title === currentTitle) return
  //     return { actionCreator: 'doUpdateAppTitle', args: [title] }
  //   },
  // ),
}
