import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    background: {
      paper: '#F7F7F7',
    },
    type: 'light',
    primary: {
      main: '#F94654',
    },
    secondary: {
      main: '#486581',
    },
    checked: {
      main: '#87CA42',
    },
    redCode: {
      main: '#F94654',
    },
    pendingTrace: {
      main: '#C4C4C4',
    },
    pendingDiagnosis: {
      main: '#F7C107',
    },
    backgroundColor: {
      main: '#F7F7F7',
    },
    grey1: {
      main: '#0000001A',
    },
    font1: {
      main: '#707070',
    },
    placeholder1: {
      main: '#9CA7B2',
    },
    selectedRed: {
      main: '#F94654',
    },
    selectedBlue: {
      main: '#486581',
    },
    success: {
      main: '#87CA42',
    },
    white: {
      main: 'white'
    },
    online: {
      main: '#52eb66',
    },
    offline: {
      main: '#cccccc',
    },
    notice: {
      main: '#4792ff',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
      'roboto',
      // 'freight-sans-pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          '&.Mui-selected': {
            backgroundColor: '#486581',
            color: 'white',
            '&:hover': {
              background: '#607d99',
              color: 'white',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: '#d1d1d1',
            opacity: 0.8,
            color: 'black',
          }
        }
      }
    }
  },
})

export default theme
