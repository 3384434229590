import axios from 'axios'
import createTableBundle from './tableBundle'
import { tokenHeaderFile } from '../utils'

const config = {
  bundleName: 'AssignTraces',
  camelCaseBundleName: 'assignTraces',
  verboseName: 'assignTraces',
  url: 'traceDocuments',
  allowDelete: true,
  extraFields: {
    assignTracesDialogOpen: false,
    selectedEcg: null,
  },
}

const baseBundle = createTableBundle(config)

export default {
  ...baseBundle,
  selectAssignTracesOpenDialog: state => state.assignTraces.assignTracesDialogOpen,
  selectAssignTracesSelectedEcg: state => state.assignTraces.selectedEcg,
  doSetAssignTracesOpenDialog: open => ({ store }) => {
    store.doSetAssignTracesStateValue('assignTracesDialogOpen', open)
  },
  doSetAssignTracesSelectedEcg: ecg => ({ store }) => {
    store.doSetAssignTracesStateValue('selectedEcg', ecg)
  },
  doAddTraceDocument: formData => async ({ store }) => {
    store.doSetAppLoading(true)
    const access = store.selectAccessToken()
    try {
      await axios.post('/api/traceDocuments/add/', formData, tokenHeaderFile(access))
      await store.doFetchAssignTracesTableList()
      store.doSetSnackbarSuccess('Trazo cargado')
      store.doSetAppIdle()
    } catch (error) {
      store.doSetSnackbarFail('Error al cargar el trazo')
      store.doSetAppIdle()
    }
  }
}
