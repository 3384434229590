const getTodayParams = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const isoDate = today.toISOString()
  return { 'created_on__gte': isoDate }
}

const FILTERING_VALUES_BY_ACTION = {
  SET_ALL: { type: 'all', value: 'all', displayValue: 'Todos', params: {} },
  SET_TODAY: { type: 'date', value: 'today', displayValue: 'Hoy', params: { 'created_on__date': 'now' } },
  SET_COMPLETED: { type: 'progress', value: 'completed', displayValue: 'Completados', params: { is_reported: true, has_trace: true } },
  SET_IN_PROGRESS: {
    type: 'progress',
    value: 'inProgress',
    displayValue: 'En proceso',
    params: { is_reported: false, has_trace: true, rejected: false },
  },
  SET_NOT_STARTED: {
    type: 'progress',
    value: 'notStarted',
    displayValue: 'No iniciados',
    params: { is_reported: false, has_trace: false, rejected: false },
  },
  SET_REJECTED: { type: 'rejected', value: 'rejected', displayValue: 'Rechazados', params: { rejected: true } },
}

const FILTERING_VALUES_BY_VALUE = {
  all: { type: 'all', action: 'SET_ALL', displayValue: 'Todos', params: {} },
  today: { type: 'date', action: 'SET_TODAY', displayValue: 'Hoy', params: { 'created_on__gte': 'today' } },
  completed: { type: 'progress', action: 'SET_COMPLETED', displayValue: 'Completados', params: { is_reported: true, has_trace: true } },
  inProgress: {
    type: 'progress',
    action: 'SET_IN_PROGRESS',
    displayValue: 'En proceso',
    params: { is_reported: false, has_trace: true, rejected: false },
  },
  notStarted: { type: 'progress', action: 'SET_NOT_STARTED', displayValue: 'No iniciados', params: { is_reported: false, has_trace: false } },
  rejected: { type: 'rejected', action: 'SET_REJECTED', displayValue: 'Rechazados', params: { rejected: true } },
}

export const types = {
  SET_COMPLETED: 'SET_COMPLETED',
  SET_IN_PROGRESS: 'SET_IN_PROGRESS',
  SET_NOT_STARTED: 'SET_NOT_STARTED',
  SET_TODAY: 'SET_TODAY',
  SET_ALL: 'SET_ALL',
}
export const allFilterOptions = Object.values(FILTERING_VALUES_BY_ACTION)
export const doctorFilterOptions = allFilterOptions.filter(opt => opt.value !== 'notStarted')
export const filterOptionsByKind = {
  0: allFilterOptions,
  1: allFilterOptions,
  2: doctorFilterOptions,
  3: allFilterOptions,
}

const initialState = {
  type: FILTERING_VALUES_BY_ACTION.SET_ALL.type,
  value: FILTERING_VALUES_BY_ACTION.SET_ALL.value,
  displayValue: FILTERING_VALUES_BY_ACTION.SET_ALL.displayValue,
  params: FILTERING_VALUES_BY_ACTION.SET_ALL.params,
}

export default {
  name: 'filters',
  reducer: (state = initialState, action) => {
    const dataByAction = FILTERING_VALUES_BY_ACTION[action.type]
    if (dataByAction === undefined) return state
    if (dataByAction.value === 'today') return {
      ...dataByAction,
      params: getTodayParams(),
    }
    return dataByAction
  },
  selectFiltersRaw: state => state.filters,
  selectFilterValue: state => state.filters.value,
  selectFilterParams: state => state.filters.params,
  selectFilterDisplayValue: state => state.filters.displayValue,
  doUpdateFilter: value => async ({ dispatch, store }) => {
    const data = FILTERING_VALUES_BY_VALUE[value]
    if (data === undefined) return
    dispatch({ type: data.action })
    store.doSetEcgsCurrentPage(1)
    store.doSetEcgsPageSize(20)
    store.doSetAppLoading()
    await store.doFetchECGS()
    store.doSetAppIdle()
  },
  init: store => {
    const kind = globalThis.localStorage.getItem('kind')
    if (kind === 2) store.doUpdateFilter('inProgress')
  },
}
