export const addDays = (date, days) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const range = (start = 0, end) => {
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}
export const dateRange = (startDate, endDate) => {
  const result = []
  const dayDiffArr = range(0, 1 + Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)))
  dayDiffArr.forEach(delta => result.push(addDays(startDate, delta).toLocaleDateString()))
  return result
}

const femenineVals = ['Femenino', 'FEMENINO', 'femenino', 'F', 'f']
const masculineVals = ['Masculino', 'MASCULINO', 'masculino ', 'M', 'm']
export const getSexValue = value => {
  if (femenineVals.includes(value)) return 'FEMENINO'
  if (masculineVals.includes(value)) return 'MASCULINO'
  return undefined
}
export const noop = () => { }

export const getInitials = str => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

  let initials = [...str.matchAll(rgx)] || []

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase()
  return initials
}

export const chunkify = (a, n, balanced) => {
  if (n < 2)
    return [a]

  var len = a.length,
    out = [],
    i = 0,
    size

  if (len % n === 0) {
    size = Math.floor(len / n)
    while (i < len) {
      out.push(a.slice(i, i += size))
    }
  }

  else if (balanced) {
    while (i < len) {
      size = Math.ceil((len - i) / n--)
      out.push(a.slice(i, i += size))
    }
  }

  else {

    n--
    size = Math.floor(len / n)
    if (len % size === 0)
      size--
    while (i < size * n) {
      out.push(a.slice(i, i += size))
    }
    out.push(a.slice(size * n))

  }

  return out
}

/*
[{1}, {2}, {3}, {4}] -> f(o) -> [ [{1},{2}], [{2},{3}], [{3},{4}] ]
*/
export const getDiffArray = ecgChangeHistories => {
  const result = []
  if (!ecgChangeHistories.length) return result
  for (let index = 0; index < ecgChangeHistories.length - 1; index++) {
    const after = ecgChangeHistories[index]
    const before = ecgChangeHistories[index + 1]
    const obj = { after, before }
    result.push(obj)
  }
  return result
}

export function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) )
}
