import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import PatientData from './PatientData'
import Spinner from '../../common/Spinner'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Viewer from '../../common/Viewer'
import History from './History'
import FileIcon from '../../common/FileIcon'
import ManageHistoryIcon from '@mui/icons-material/ManageHistory'
import DiffView from '../../DiffView'

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateAreas: [
      '"columnLeft columnRight"',
    ].join('\n'),
    gridTemplateColumns: '1fr 1fr',
    overflowY: 'scroll',
  },
  tabs: {
    display: 'flex',
    gridArea: 'tabs',
  },
  columnLeft: {
    display: 'flex',
    gridArea: 'columnLeft',
    overflowY: 'scroll',
    padding: 20,
  },
  columnRight: {
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'columnRight',
    overflowY: 'scroll',
    height: '100%',
  },
  spinnerDiv: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  filesDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: 5,
    width: '100%',
    height: '100%',
    justifyContent: 'left',
    padding: 20,
  }
}))

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const TabPanel = props => {
  const { children, value, index } = props
  return value === index && (
    <Box sx={{ height: '100%' }}>
      {children}
    </Box>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const ChangesTabView = () => (
  <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
    Historial
    <ManageHistoryIcon fontSize='small' />
  </div>
)

const EditDialog = props => {
  const {
    open,
    onClose,
    ecgData,
    loading,
    pdfKey,
    kind,
    historicEcgs,
    ecgChangeHistories,
    doUpdateUrl,
    doFetchEcgDialogData,
    doAddFile,
    doMarkEcgAsRead,
  } = props
  const [height, setHeight] = useState(0)
  const ref = useRef(null)
  // const isRead = ecgData?.isRead

  useEffect(() => {
    setHeight(ref?.current?.clientHeight)
  }, [open])

  // useEffect(() => {
  //   return async () => {
  //     if (open && ecgData && !loading && !isRead) await doMarkEcgAsRead(ecgData)
  //   }
  // }, [open])

  const [value, setValue] = useState(0)
  const classes = useStyles()
  const validDocuments = ecgData?.documents || []
  return (
    <Dialog
      open={open}
      onClose={async () => {
        doUpdateUrl('/')
        onClose()
        await doMarkEcgAsRead(ecgData)
        setValue(0)
      }}
      maxWidth='xl'
      PaperProps={{
        style: {
          borderRadius: 20,
          backgroundColor: 'white',
          minHeight: '90vh',
        }
      }}
      fullWidth
    >
      <div className={classes.root}>
        <div name='columnLeft' className={classes.columnLeft}>
          {loading ? (
            <div className={classes.spinnerDiv}>
              <Spinner />
            </div>
          ) : (
            <PatientData ecgData={ecgData} />
          )}
        </div>
        <div name='columnRight' className={classes.columnRight}>
          <Tabs
            value={value}
            onChange={(_, newVal) => setValue(newVal)}
            indicatorColor='primary'
            textColor='inherit'
            variant='fullWidth'
            aria-label='full width tabs example'
          >
            <Tab label='Trazo' {...a11yProps(0)} />
            <Tab label='Expediente' {...a11yProps(1)} />
            <Tab label='Archivos' {...a11yProps(2)} />
            {kind <= 1 && <Tab label={<ChangesTabView />} {...a11yProps(3)} />}
          </Tabs>
          <TabPanel value={value} index={0}>
            <Viewer
              apiKey={pdfKey}
              ecgData={ecgData}
              doFetchEcgDialogData={doFetchEcgDialogData}
              kind={kind}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <History historicEcgs={historicEcgs} currentId={ecgData?.id} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div ref={ref} className={classes.filesDiv}>
              {validDocuments
                ?.sort((a, b) => b.name.startsWith('CONFIRMED__') - a.name.startsWith('CONFIRMED__') )
                ?.map(doc => (
                  <FileIcon key={doc.filename} fileData={doc} size={height * 0.3} kind={kind} />
                ))}
              {kind > 1 && !validDocuments.length && (<Typography textAlign='center' color='#5c5c5c' fontSize={16}>No hay archivos</Typography>)}
              {kind <= 1 && <FileIcon key='add' isNew size={height * 0.3} onClick={doAddFile} ecgData={ecgData} />}
            </div>
          </TabPanel>
          {kind <= 1 && (
            <TabPanel value={value} index={3}>
              <DiffView ecgChangeHistories={ecgChangeHistories} />
            </TabPanel>
          )}
        </div>
      </div>
    </Dialog>
  )
}

EditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  ecgData: PropTypes.object,
  loading: PropTypes.bool,
}

export default EditDialog
