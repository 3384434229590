import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import { makeStyles } from '@mui/styles'
import { useFormik } from 'formik'
import Slide from '@mui/material/Slide'
import TextField from '../components/common/TextField'
import Button from '../components/common/Button'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import { ReactComponent as Logo } from '../assets/static/icons/Logo.svg'
import * as Yup from 'yup'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'grid',
    gridTemplateAreas: '"logo form"',
    gridTemplateColumns: '1fr 1fr',
    '@media (max-width: 760px)': { //isMobile
      gridTemplateAreas: `
        "logo"
        "form"
      `,
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 3fr',
    },
    minHeight: '100vh',
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
  },
  logo: {
    gridArea: 'logo',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10%',
  },
  form: {
    gridArea: 'form',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 760px)': {
      justifyContent: 'start',
    },
  },
  paper: {
    backgroundColor: theme.palette.backgroundColor.main,
    width: '80%',
    minHeight: 350,
    borderRadius: 38,
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 100,
    margin: '0px 40px 0px 40px',
    height: '100%',
    gap: 10,
  },
  formFields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: 36,
  },
  forgotAnchor: {
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
    width: '100%',
    color: theme.palette.primary,
    fontSize: 12,
  },
  submitButton: {
    alignSelf: 'flex-end',
    width: '40%',
  }
}))

const Login = props => {
  const {
    doLogin,
    isMobile
  } = props
  const classes = useStyles(isMobile)
  const helperTextStylesError = {
    color: 'red'
  }
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async (values, actions) => {
      await doLogin(values.username, values.password)
      actions.setSubmitting(false)
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Campo requerido'),
      password: Yup.string().required('Campo requerido'),
    }),
    validateOnChange: true,
  })

  return (
    <Slide in>
      <div
        className={classes.root}
      >
        <div className={classes.logo}>
          {/* <img width='50%' src={LogoIcon} /> */}
          <Logo />
        </div>
        <div className={classes.form}>
          <div className={classes.paper}>
            <form className={classes.fields} onSubmit={formik.handleSubmit}>
              <div className={classes.formFields}>
                <TextField
                  autoFocus
                  fullWidth
                  placeholder='Usuario'
                  adornmentPosition='start'
                  icon={PersonIcon}
                  name='username'
                  id='username'
                  onValueChange={formik.handleChange}
                  value={formik.values.username}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.touched.username && formik.errors.username)}
                  helperTextStyles={helperTextStylesError}
                  helperText={formik.touched.username && formik.errors.username ? (
                    formik.errors.username
                  ) : null}
                  styles={{
                    texttransform: 'lowercase',
                  }}
                  autoCapitalize='off'
                />
                <TextField
                  fullWidth
                  placeholder='Contraseña'
                  adornmentPosition='start'
                  icon={LockIcon}
                  name='password'
                  id='password'
                  hidden
                  onValueChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={Boolean(formik.touched.password && formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password ? (
                    formik.errors.password
                  ) : null}
                  helperTextStyles={helperTextStylesError}
                />
              </div>
              <div className={classes.forgotAnchor}>
                <a href='/forgot'>
                    Reestablecer contraseña
                </a>
              </div>
              <div className={classes.submitButton}>
                <Button
                  disabled={formik.isSubmitting || !formik.isValid}
                  type='submit'
                  fullWidth
                  size='large'
                >
                  Ingresar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Slide>
  )
}

export default () => {
  const connectedProps = useConnect(
    'selectIsAuthenticated',
    'doUpdateUrl',
    'doLogin',
    'selectIsMobile'
  )
  return <Login {...connectedProps} />
}
