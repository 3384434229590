import axios from 'axios'
// import { createSelector } from 'redux-bundler'
import { tokenHeader } from '../utils'

export const types = {
  GET_BASE_URL: 'GET_BASE_URL',
  GET_PDF_KEY: 'GET_PDF_KEY',
  GET_SERVER_INFO: 'GET_SERVER_INFO',
  SET_SERVER_INFO: 'SET_SERVER_INFO',
  GET_STATS_FOR_NERDS: 'GET_STATS_FOR_NERDS',
  OPEN_STATS_FOR_NERDS: 'OPEN_STATS_FOR_NERDS',
  CLOSE_STATS_FOR_NERDS: 'CLOSE_STATS_FOR_NERDS',
}

const initialState = {
  baseUrl: '',
  pdfKey: '',
  backendVersion: '',
  openNerdStatsDialog: false,
  statsForNerds: null,
}

export default {
  name: 'server',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.GET_BASE_URL:
      return {
        ...state,
        baseUrl: action.payload?.baseUrl
      }
    case types.GET_PDF_KEY:
      return {
        ...state,
        pdfKey: action.payload?.pdfKey,
      }
    case types.GET_SERVER_INFO:
      return {
        ...state,
        ...action.payload,
      }
    case types.SET_SERVER_INFO:
      return {
        ...state,
        ...action.payload,
      }
    case types.GET_STATS_FOR_NERDS:
      return {
        ...state,
        statsForNerds: action.payload,
      }
    case types.OPEN_STATS_FOR_NERDS:
      return {
        ...state,
        openNerdStatsDialog: true,
      }
    case types.CLOSE_STATS_FOR_NERDS:
      return {
        ...state,
        openNerdStatsDialog: false,
      }
    default:
      return state
    }
  },
  selectServer: state => state.server,
  selectPdfKey: state => state.server.pdfKey,
  selectOpenNerdStatsDialog: state => state.server.openNerdStatsDialog,
  selectStatsForNerds: state => state.server.statsForNerds,
  doOpenStatsForNerdsDialog: () => ({ dispatch }) => dispatch({ type: types.OPEN_STATS_FOR_NERDS }),
  doCloseStatsForNerdsDialog: () => ({ dispatch }) => dispatch({ type: types.CLOSE_STATS_FOR_NERDS }),
  doCacheServerInfo: () => ({ store }) => globalThis.localStorage.setItem('cachedServerInfo', JSON.stringify(store.selectServer())),
  doSetServerInfo: data => ({ dispatch }) => dispatch({ type: types.SET_SERVER_INFO, payload: data }),
  doClearServerInfo: () => ({ dispatch }) => {
    globalThis.localStorage.setItem('cachedServerInfo', JSON.stringify({}))
    dispatch({ type: types.SET_SERVER_INFO, payload: initialState })
  },
  doFetchBaseURL: () => async ({ dispatch, store }) => {
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/baseUrl', tokenHeader(access))
      dispatch({
        type: types.GET_BASE_URL,
        payload: res.data,
      })
      store.doCacheServerInfo()
    } catch (error) {
      console.log(error)
    }
  },
  doFetchPDFKey: () => async ({ dispatch, store }) => {
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/pdfKey', tokenHeader(access))
      dispatch({
        type: types.GET_PDF_KEY,
        payload: res.data,
      })
      store.doCacheServerInfo()
    } catch (error) {
      console.error(error)
    }
  },
  doFetchServerInfo: () => async ({ store }) => {
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/server', tokenHeader(access))
      store.dispatch({
        type: types.GET_SERVER_INFO,
        payload: res.data,
      })
      store.doCacheServerInfo()
      store.doSetAppIdle()
    } catch (error) {
      console.error(error)
      store.doSetAppIdle()
    }
  },
  doFetchClientVersion: () => async ({ store }) => {
    store.doSetAppLoading()
    let res
    try {
      res = await axios.get('/api/clientVersion')
      const data = res.data
      localStorage.setItem('latestVersion', data.latestClientVersion)
      store.doCacheServerInfo()
      store.doSetAppIdle()
    } catch (error) {
      console.error(error)
    }
  },
  doFetchStatsForNerds: () => async ({ store, dispatch }) => {
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/health', tokenHeader(access))
      const data = res.data
      dispatch({
        type: types.GET_STATS_FOR_NERDS,
        payload: data,
      })
    } catch (error) {
      store.doSetSnackbarFail('Error while loading stats 🤦‍ ⚙️')
    }
  },
  // reactShouldFetchServer: createSelector(
  //   'selectServer',
  //   'selectIsAuthenticated',
  //   'selectAppTime',
  //   () => {},
  // ),
  init: store => {
    store.doFetchClientVersion()
    store.doFetchServerInfo()
    store.doFetchPDFKey()
    const data = globalThis.localStorage.getItem('cachedServerInfo')
    if (data === null) return
    const parsedData = JSON.parse(data)
    store.doSetServerInfo(parsedData)

    if (store.selectIsAuthenticated() && store.selectKind() === 0) {
      store.doFetchStatsForNerds()
    }
  }
}
