import { IconButton, Tooltip, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import InfoIcon from '@mui/icons-material/Info'
import { makeStyles } from '@mui/styles'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import React from 'react'
import UndoIcon from '@mui/icons-material/Undo'
import { timeSince } from '../../utils/dates'

const notificationLevels = {
  'DANGER': <ErrorIcon style={{ color: '#F94654' }} />,
  'SUCCESS': <CheckCircleIcon style={{ color: '#87CA42' }} />,
  'WARNING': <DoDisturbOnIcon style={{ color: '#F7C107' }} />,
  'NOTICE': <InfoIcon color='notice' />,
}

const useStyles = makeStyles({
  paper: props => ({
    borderRadius: 15,
    backgroundColor: '#EEEEEE',
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: '#DDDDDD',
    },
    cursor: props.hasEcg ? 'pointer' : 'default',
    zIndex: 10,
    minHeight: 80,
    width: '100%',
    minWidth: props.width,
  }),
  container: props => ({
    display: 'grid',
    gridTemplateAreas: [
      '"icon title title title title time time"',
      '"dismiss message message message message date date"'
    ].join('\n'),
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridTemplateRows: '35px 35px',
    maxWidth: props.width,
    // margin: 2,
  }),
  snoozedContainer: props => ({
    display: 'grid',
    gridTemplateAreas: [
      '"undo message"'
    ].join('\n'),
    gridTemplateColumns: '1fr 4fr',
    gap: 2,
    height: '100%',
    minHeight: 80,
    minWidth: props.width,
  }),
  undo: {
    gridArea: 'undo',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconDiv: {
    display: 'flex',
    gridArea: 'icon',
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageDiv: {
    gridArea: 'message',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    wordWrap: 'break-word',
  },
  titleDiv: {
    gridArea: 'title',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    wordWrap: 'break-word',
  },
  snoozedMessageDiv: props => ({
    gridArea: 'message',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: props.width,
    width: '100%',
  }),
  dateDiv: {
    gridArea: 'date',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    fontSize: 12,
    marginRight: '15%'
  },
  timeDiv: {
    gridArea: 'time',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    fontSize: 12,
    marginRight: '15%'
  },
  dismissDiv: {
    gridArea: 'dismiss',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
  },
})

const NotificationBanner = props => {
  const {
    notification,
    width,
    doSnoozeNotification,
    doUnsnoozeNotification,
    doUpdateUrl,
    doOpenEcgDialogOnUrlUpdate,
  } = props
  const tsDate = new Date(notification.ts)
  const danger = notification.level === 'DANGER'
  const today = new Date()
  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)
  const time = timeSince(tsDate, danger)
  let date = tsDate.toLocaleDateString()
  if (tsDate.toLocaleDateString() == today.toLocaleDateString()) date = 'Hoy'
  else if (tsDate.toLocaleDateString() == yesterday.toLocaleDateString()) date = 'Ayer'
  const hasEcg = notification?.ecg ?? false
  const handleSnooze = async event => {
    event.stopPropagation()
    await doSnoozeNotification(notification.id)
  }
  const styles = useStyles({ dismissed: notification.dismissed, hasEcg, width })
  return notification.dismissed ? (
    <div className={styles.paper}>
      <div className={styles.snoozedContainer}>
        <div className={styles.undo}>
          <Tooltip title='Deshacer'>
            <IconButton onClick={async () => await doUnsnoozeNotification(notification.id)}>
              <UndoIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={notification.dismissed ? styles.snoozedMessageDiv : styles.messageDiv}>
          <Typography>Notificación silenciada</Typography>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={styles.paper}
      onClick={() => {
        if (!hasEcg) return
        doUpdateUrl(`/estudio/${notification.ecg}/`)
        doOpenEcgDialogOnUrlUpdate(notification.ecg, true)
      }}
    >
      <div className={styles.container}>
        <div className={styles.iconDiv}>
          {notificationLevels[notification.level]}
        </div>
        <div className={styles.dismissDiv}>
          <Tooltip title="Marcar como leído" placement="bottom">
            <IconButton onClick={handleSnooze} size="large">
              <NotificationsOffIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={styles.titleDiv}>
          <Typography>
            {notification?.notificationType?.name}
          </Typography>
        </div>
        <div className={styles.messageDiv}>
          <Typography fontSize={13}>
            {notification.message}
          </Typography>
        </div>
        <div className={styles.timeDiv}>
          {time}
        </div>
        <div className={styles.dateDiv}>
          {date}
        </div>
      </div>
    </div>
  )
}

export default NotificationBanner
