import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import T from './Typography'
import { noop } from '../../utils/misc'

const ECGTextField = props => {
  const {
    placeholder,
    adornmentPosition,
    icon: Icon,
    value,
    onValueChange,
    type,
    hidden,
    helperText,
    helperTextStyles,
    fullWidth,
    rounded,
    dense,
    isNumber,
    disabled,
    styles,
    autoCapitalize,
    startAdornmentText = '',
    endAdornmentText = '',
    ...rest
  } = props
  const [inputType, setInputType] = useState(hidden ? 'password' : type ?? 'text')
  const [pwdVisible, setPwdVisible] = useState(false)
  const handleSeePwd = () => {
    if (pwdVisible) {
      setInputType('password')
      setPwdVisible(false)
    } else {
      setInputType('text')
      setPwdVisible(true)
    }
  }
  const inputProps = isNumber ? { inputMode: 'numeric', pattern: '[0-9]*' } : {}

  return (
    <FormControl fullWidth={fullWidth} variant="standard">
      <OutlinedInput
        fullWidth={fullWidth}
        inputProps={{...inputProps}}
        style={{
          border: '1px solid #C4C4C4',
          backgroundColor: 'white',
          fontFamily: 'roboto',
          borderRadius: rounded ? '50px' : '21px',
          borderStyle: rounded ? 'solid' : 'none',
          borderWidth: rounded ? 1.5 : '',
          borderColor: rounded ? '#486581' : '',
          height: dense ? 45 : '',
          ...styles,
        }}
        disabled={disabled}
        placeholder={placeholder}
        id="input-with-icon-adornment"
        startAdornment={adornmentPosition === 'start' && (
          <InputAdornment position={adornmentPosition}>
            {Icon && <Icon />}
            {startAdornmentText}
          </InputAdornment>
        )}
        endAdornment={adornmentPosition === 'end' ? (
          <InputAdornment position={adornmentPosition}>
            {Icon && <Icon />}
            {endAdornmentText}
          </InputAdornment>
        ) : hidden && (
          <IconButton onClick={handleSeePwd}>
            {pwdVisible ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
          </IconButton>
        )}
        value={value}
        onChange={disabled ? noop : onValueChange}
        type={inputType}
        autoCapitalize={autoCapitalize}
        {...rest}
      />
      <T.Tiny style={helperTextStyles}>
        {helperText}
      </T.Tiny>
    </FormControl>
  )
}

ECGTextField.propTypes = {
  placeholder: PropTypes.string,
  adornmentPosition: PropTypes.string,
  icon: PropTypes.elementType,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValueChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  dense: PropTypes.bool,
  rounded: PropTypes.bool,
  helperText: PropTypes.string,
  helperTextStyles: PropTypes.object,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  isNumber: PropTypes.bool,
  styles: PropTypes.object,
  autoCapitalize: PropTypes.string,
}
ECGTextField.defaultProps = {
  isNumber: false,
  styles: {},
}

export default ECGTextField
