import { createRouteBundle } from 'redux-bundler'
import App from '../containers/App'
import Login from '../containers/Login'
import ForgotForm from '../containers/ForgotForm'
import ResetPassword from '../containers/ResetPassword'
import Report from '../containers/Report'
import AdminPanel from '../containers/AdminPanel'
import PublicReport from '../containers/PublicReport'
import NotFound from '../containers/NotFound'

const routes = {
  '/': {
    component: App,
    titleTemplate: 'Dashboard'
  },
  '/login': {
    component: Login,
    titleTemplate: 'Iniciar Sesión',
  },
  '/estudio/:ecgId/': {
    component: App,
    titleTemplate: 'Estudio',
  },
  '/forgot': {
    component: ForgotForm,
    titleTemplate: 'Olvidé mi contraseña'
  },
  '/reset': {
    component: ResetPassword,
    titleTemplate: 'Reestablecer Contraseña'
  },
  '/reportarFraude': {
    component: Report,
    titleTemplate: 'Reportado'
  },
  '/administracion/:tab': {
    component: AdminPanel,
    titleTemplate: 'Panel de Administración',
    defaultTab: 'centros',
  },
  '/p/r/:encodedId/': {
    component: PublicReport,
    titleTemplate: 'CIDC - Reporte',
  },
  '*': {
    component: NotFound,
    titleTemplate: '404'
  }
}

export default createRouteBundle(routes)
