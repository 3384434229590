import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useConnect } from 'redux-bundler-hook'
import FileIcon from '../common/FileIcon'
import { useDropzone } from 'react-dropzone'
import { onDropMapping } from './UploadFileDialog'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
    height: '100%',
    border: 'dashed 2px #486581',
    borderRadius: 20,
    minWidth: '100%',
    backgroundColor: '#F2F2F2',
    marginTop: 20,
    '&:hover': {
      backgroundColor: '#e0e0e0',
      cursor: 'pointer',
    },
  },
}))

const FileBrowserComponent = props => {
  const {
    kind,
    fileBrowserOpen,
    fileBrowserFiles,
    doSetFileBrowserClose,
    doSetFileBrowserClearFiles,
    doSetAppLoading,
    fileBrowserEcg,
    doSetAppIdle,
    doSetFileBrowserSetFiles,
  } = props
  const classes = useStyles()
  const onDropAction = onDropMapping.uploadConfirmedTrace

  const onDrop = useCallback(async acceptedFiles => {
    doSetAppLoading()
    const newData = await onDropAction(acceptedFiles, fileBrowserEcg)
    doSetFileBrowserSetFiles(newData)
    doSetAppIdle()
  })
  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({ onDrop })

  return (
    <Dialog
      open={fileBrowserOpen}
      maxWidth='md'
      fullWidth
      onClose={() => {
        doSetFileBrowserClose()
        doSetFileBrowserClearFiles()
      }}
    >
      <DialogTitle fontSize={40}>Archivos 📁</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', gap: 10 }}>
          {fileBrowserFiles.map(fileObj => (
            <FileIcon key={fileObj.filename} fileData={fileObj} size={200} kind={10} />
          ))}
        </div>
        {kind <= 1 && (
          <div className={classes.dropzone} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography fontFamily='freight-sans-pro'>Suelte archivo aquí</Typography>
            ) : (
              <Typography fontFamily='freight-sans-pro'>Arrastre archivos o haga click para cargar un archivo.</Typography>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectKind',
    'selectFileBrowserOpen',
    'selectFileBrowserEcg',
    'selectFileBrowserFiles',
    'doSetFileBrowserClose',
    'doSetFileBrowserSetFiles',
    'doSetFileBrowserClearFiles',
    'doSetAppLoading',
    'doSetAppIdle',
  )
  return <FileBrowserComponent {...props} {...connectedProps} />
}
