import React, { useState } from 'react'
import Table from '../../../components/CommonTable/Web'
import { useConnect } from 'redux-bundler-hook'
import { Button, Typography } from '@mui/material'
import { tableDataProcessor } from '../../../components/CommonTable/Web/utils'
import { noop } from '../../../utils/misc'
import CentersAdminDialog from './CentersAdminDialog'
import { makeStyles } from '@mui/styles'
import ProvisionCenter from './ProvisionCenter'

const centerHeaderFields = [
  {
    key:'id',
    displayValue: 'ID',
    field: 'id',
    sizePercentage: 10,
    component: Typography,
    propsMap: {
      color: 'black',
      fontWeight: 'light',
      fontSize: 14,
    },
    cellProps: {
      align: 'center',
    },
  },
  {
    key: 'name',
    displayValue: 'Nombre',
    field: 'name',
    sizePercentage: 10,
    component: Typography,
    propsMap: {
      color: 'black',
      fontWeight: 'light',
      fontSize: 14,
    },
    cellProps: {
      align: 'center',
    },
  },
  {
    key: 'city',
    displayValue: 'Ciudad',
    field: 'city',
    sizePercentage: 10,
    component: Typography,
    propsMap: {
      color: 'black',
      fontWeight: 'light',
      fontSize: 14,
    },
    cellProps: {
      align: 'center',
    },
  },
  // {
  //   key: 'template',
  //   displayValue: 'Plantilla',
  //   field: 'template',
  //   nestedProps: ['key'],
  //   sizePercentage: 10,
  //   component: Typography,
  //   propsMap: {
  //     color: 'black',
  //     fontWeight: 'light',
  //     fontSize: 14,
  //   },
  //   cellProps: {
  //     align: 'center',
  //   },
  // },
  {
    key: 'organization',
    displayValue: 'Organización',
    field: 'organization',
    nestedProps: ['name'],
    sizePercentage: 10,
    component: Typography,
    propsMap: {
      color: 'black',
      fontWeight: 'light',
      fontSize: 14,
    },
    cellProps: {
      align: 'center',
    },
  },
  {
    key: 'user',
    displayValue: 'Usuario',
    field: 'user',
    nestedProps: ['username'],
    sizePercentage: 10,
    component: Typography,
    propsMap: {
      color: 'black',
      fontWeight: 'light',
      fontSize: 14,
    },
    cellProps: {
      align: 'center',
    },
  },
]

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
}))

const CentersAdminComponent = props => {
  const classes = useStyles()
  const [openProvisionCenter, setOpenProvisionCenter] = useState(false)
  const {
    tableData,
    selectedItems,
    centerTableAllowDelete,
    allSelected,
    searchValue,
    centerTableDialogOpen,
    doSetCenterSelectedItems,
    doSetAllCenterItemsSelected,
    doClearCenterItemsSelected,
    doSetCenterSearchValue,
    doSetCenterTablePage,
    doSetCenterTablePageSize,
    doOpenCenterTableDialog,
    doDeleteCenterTableItem,
  } = props
  return (
    <div className={classes.root}>
      {centerTableDialogOpen && <CentersAdminDialog />}
      <ProvisionCenter open={openProvisionCenter} setOpen={setOpenProvisionCenter} />
      <div className={classes.actions}>
        <Button variant='outlined' color='secondary' onClick={() => setOpenProvisionCenter(true)}>
          Inscribir Centro
        </Button>
      </div>
      <Table
        {...props}
        headers={centerHeaderFields}
        processedData={tableData}
        selectedItems={selectedItems}
        allSelected={allSelected}
        setSelectedItems={doSetCenterSelectedItems}
        setAllSelected={doSetAllCenterItemsSelected}
        clearSelected={doClearCenterItemsSelected}
        searchValue={searchValue}
        setSearchValue={doSetCenterSearchValue}
        setCurrentPage={doSetCenterTablePage}
        setPageSize={doSetCenterTablePageSize}
        openDialog={doOpenCenterTableDialog}
        allowDelete={centerTableAllowDelete}
        deleteItem={doDeleteCenterTableItem}
      />
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectCenterTableAllowDelete',
    'selectCenterSelectedItems',
    'selectCenterTableDialogOpen',
    'selectCenterTableRaw',
    'doSetCenterSelectedItems',
    'doSetAllCenterItemsSelected',
    'doClearCenterItemsSelected',
    'doSetCenterSearchValue',
    'doSetCenterTablePage',
    'doSetCenterTablePageSize',
    'doOpenCenterTableDialog',
    'doDeleteCenterTableItem',
  )
  const { centerTableRaw, centerSelectedItems } = connectedProps
  const {
    items,
  } = centerTableRaw
  const actions = [
    {
      displayValue: 'Ver',
      actionFunction: noop,
      key: 'open',
      icon: null,
    }
  ]

  const tableData = tableDataProcessor(items, centerHeaderFields)
  return (
    <CentersAdminComponent
      {...props}
      {...connectedProps}
      {...centerTableRaw}
      tableData={tableData}
      selectedItems={centerSelectedItems}
      actions={actions}
    />
  )
}
