import { stringToHexFormat } from './string'

export const parseRgbStringToValues = rgbString => {
  const result = { r: 0, g: 0, b: 0 }
  if (typeof rgbString !== 'string') return {}
  const numbersOnly = rgbString.replace('#', '')
  if (numbersOnly.length !== 6) return result
  const rString = numbersOnly.slice(0, 2)
  const gString = numbersOnly.slice(2, 4)
  const bString = numbersOnly.slice(4, 6)
  result.r = Number(stringToHexFormat(rString))
  result.g = Number(stringToHexFormat(gString))
  result.b = Number(stringToHexFormat(bString))
  return result
}

export const componentToHex = c => {
  var hex = c.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}

export const rgbToHex = rgbObj => {
  const { r, g, b } = rgbObj
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const shadeRGBValueToInt = (val, shadeVal) => Math.trunc(val * shadeVal)
export const shadeRGBVals = (rgb, shade) => (
  {
    r: shadeRGBValueToInt(rgb.r, shade),
    g: shadeRGBValueToInt(rgb.g, shade),
    b: shadeRGBValueToInt(rgb.b, shade),
  }
)

export function stringToColor(string) {
  let hash = 400
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export function getContrastTextColor(hexColor) {
  // Convert hex color to RGB
  let r = parseInt(hexColor.substr(1, 2), 16)
  let g = parseInt(hexColor.substr(3, 2), 16)
  let b = parseInt(hexColor.substr(5, 2), 16)

  // Calculate the luminance
  let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Return black or white depending on the luminance
  return luminance > 0.5 ? '#000000' : '#ffffff'
}
