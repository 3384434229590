import createTableBundle from './tableBundle'

const config = {
  bundleName: 'Devices',
  camelCaseBundleName: 'devices',
  verboseName: 'devices',
  url: 'devices',
  allowDelete: true,
  extraFields: {
    deviceNavDialogOpen: false,
  },
  autoFetchMs: 10000,
  emptyAutoFetchMs: 30000,
}

const baseBundle = createTableBundle(config)

export default {
  ...baseBundle,
  selectDeviceNavDialogOpen: state => state.devices.deviceNavDialogOpen,
  doSetDeviceNavDialogOpen: open => ({ store }) => {
    store.doSetDevicesStateValue('deviceNavDialogOpen', open)
  },
}
