import React, { useState } from 'react'
import { useConnect } from 'redux-bundler-hook'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Tooltip from '@mui/material/Tooltip'
import AssignTraceTable from '../AssignTraceTable'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import Button from '@mui/material/Button'

const getTooltipTitle = (ecg, selectedDoc) => {
  const titles = []
  if (ecg?.rejected) titles.push('El estudio ha sido rechazado.')
  if (ecg?.isReported || ecg?.diagnosedBy) titles.push('El estudio ya ha sido reportado o diagnosticado.')
  if (ecg?.assignedTrace?.id === selectedDoc?.id && selectedDoc) titles.push('Este trazo ya está asignado a este estudio.')
  if (ecg?.centerInfo?.id !== selectedDoc?.center?.id && selectedDoc) titles.push('El trazo no es del mismo centro que el estudio.')
  if (selectedDoc && selectedDoc?.ecgPtr !== null && selectedDoc?.ecgPtr !== ecg?.id) titles.push('El trazo ya está asignado a otro estudio.')
  if (!selectedDoc) titles.push('No se ha seleccionado un trazo.')
  return titles.join('\n')
}

const AssignTraceDialog = props => {
  const {
    kind,
    centers,
    userCenterId,
    assignTracesOpenDialog,
    assignTracesSelectedEcg,
    assignTracesTableSelectedItem,
    doSetAssignTracesOpenDialog,
    doSetAssignTracesSelectedEcg,
    doAssignTraceToEcg,
    doAddTraceDocument,
    doSetAssignTracesTableSelectedItem,
  } = props
  const [selectedFile, setSelectedFile] = useState(null)
  const [traceForm, setTraceForm] = useState({
    center: userCenterId,
  })
  let tooltipTitle = getTooltipTitle(assignTracesSelectedEcg, assignTracesTableSelectedItem)
  const handleAddTrace = async e => {
    e.preventDefault()
    const data = new FormData()
    console.log(selectedFile)
    console.log(typeof selectedFile)
    data.append('filePtr', selectedFile)
    data.append('documentType', 'trace')
    data.append('name', selectedFile.name)
    data.append('center', traceForm.center)
    console.log(data)
    await doAddTraceDocument(data)
  }
  return (
    <Dialog
      open={assignTracesOpenDialog}
      onClose={() => {
        doSetAssignTracesOpenDialog(false)
        doSetAssignTracesSelectedEcg(null)
        doSetAssignTracesTableSelectedItem(null)
      }}
      maxWidth='xl'
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1, fontFamily: 'roboto', fontWeight: 'regular' }}>
        <DocumentScannerIcon />
        Asignar Trazo - ECG: {assignTracesSelectedEcg?.id} - {assignTracesSelectedEcg?.patient?.name} - {assignTracesSelectedEcg?.centerInfo?.name}
      </DialogTitle>
      <DialogContent>
        <AssignTraceTable selectedEcg={assignTracesSelectedEcg} />
      </DialogContent>
      <DialogActions sx={{ width: '100%', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <small>Cargar Trazo</small>
          <form
            style={{ display: 'flex' }}
            onSubmit={handleAddTrace}
          >
            <input
              name='fileinput'
              type='file'
              required
              accept='application/pdf'
              onChange={e => {
                console.log(e.target.files)
                setSelectedFile(e.target.files[0])
              }}
            />
            {kind <= 1 && (
              <select
                value={traceForm.center}
                placeholder='Centro'
                onChange={e => setTraceForm({ ...traceForm, center: parseInt(e.target.value, 10)})}
              >
                <option value={null}>-</option>
                {centers.map(center => (
                  <option key={center.id} value={center.id}>
                    {center.name}
                  </option>
                ))}
              </select>
            )}
            <button type='submit'>
              Cargar
            </button>
          </form>
        </div>
        <Tooltip title={tooltipTitle} placement='top' arrow open={!!tooltipTitle}>
          <Button
            variant='contained'
            color='info'
            disabled={
              !assignTracesTableSelectedItem
              || assignTracesTableSelectedItem?.id === assignTracesSelectedEcg?.assignedTrace?.id
              || assignTracesSelectedEcg?.rejected
              || assignTracesSelectedEcg?.isReported
              || !!assignTracesSelectedEcg?.diagnosedBy
              || assignTracesSelectedEcg?.centerInfo?.id !== assignTracesTableSelectedItem?.center?.id
              || assignTracesTableSelectedItem?.ecgPtr !== null
            }
            onClick={async () => {
              const result = await doAssignTraceToEcg(assignTracesTableSelectedItem?.id, assignTracesSelectedEcg?.id)
              if (result) {
                doSetAssignTracesOpenDialog(false)
                doSetAssignTracesSelectedEcg(null)
              }
            }}
          >
            {assignTracesSelectedEcg?.assignedTrace ? 'Reasignar Trazo' : 'Asignar Trazo'}
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectKind',
    'selectCenters',
    'selectUserCenterId',
    'selectAssignTracesOpenDialog',
    'selectAssignTracesSelectedEcg',
    'selectAssignTracesTableSelectedItem',
    'doSetAssignTracesOpenDialog',
    'doSetAssignTracesSelectedEcg',
    'doSetAssignTracesTableSelectedItem',
    'doAssignTraceToEcg',
    'doAddTraceDocument',
  )

  return <AssignTraceDialog {...props} {...connectedProps} />
}
