import axios from 'axios'
import { createSelector } from 'redux-bundler'
import { tokenHeader } from '../utils'

export const types = {
  CLEAR_CONVERSATIONS_DATA: 'CLEAR_CONVERSATIONS_DATA',
  GET_CONVERSATIONS_START: 'GET_CONVERSATIONS_START',
  GET_CONVERSATIONS_ERROR: 'GET_CONVERSATIONS_ERROR',
  GET_CONVERSATIONS_SUCCESS: 'GET_CONVERSATIONS_SUCCESS',
  GET_CONVERSATION_MESSAGES_START: 'GET_CONVERSATION_MESSAGES_START',
  GET_CONVERSATION_MESSAGES_ERROR: 'GET_CONVERSATION_MESSAGES_ERROR',
  GET_CONVERSATION_MESSAGES_SUCCESS: 'GET_CONVERSATION_MESSAGES_SUCCESS',
  ADD_CONVERSATION: 'ADD_CONVERSATION',
  ADD_CONVERSATION_ERROR: 'ADD_CONVERSATION_ERROR',
  POST_MESSAGE_TO_CONVERSATION: 'POST_MESSAGE_TO_CONVERSATION',
  OPEN_MESSAGES_DRAWER: 'OPEN_MESSAGES_DRAWER',
  CLOSE_MESSAGES_DRAWER: 'CLOSE_MESSAGES_DRAWER',
  SET_SELECTED_CONVERSATION: 'SET_SELECTED_CONVERSATION',
  CLEAR_SELECTED_CONVERSATION: 'CLEAR_SELECTED_CONVERSATION',
  SET_SELECTED_MESSAGES: 'SET_SELECTED_MESSAGES',
  CLEAR_SELECTED_MESSAGES: 'CLEAR_SELECTED_MESSAGES',
  SET_CONVERSATIONS_LOADING: 'SET_CONVERSATIONS_LOADING',
  SET_CONVERSATIONS_IDLE: 'SET_CONVERSATIONS_IDLE',
  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
  CLEAR_SEARCH_VALUE: 'CLEAR_SEARCH_VALUE',
  CLEAR_CACHED_CONVERSATIONS: 'CLEAR_CACHED_CONVERSATIONS',
  CLEAR_CACHED_MESSAGES_BY_CONVERSATION: 'CLEAR_CACHED_MESSAGES_BY_CONVERSATION',
  MARK_BULK_AS_READ: 'MARK_BULK_AS_READ',
  MARK_THEIRS_AS_READ: 'MARK_THEIRS_AS_READ',
  SET_MESSAGES_BY_CONVERSATION: 'SET_MESSAGES_BY_CONVERSATION',
  SET_MESSAGES_OF_CONVERSATION: 'SET_MESSAGES_OF_CONVERSATION',
  APPEND_MESSAGE_TO_CONVERSATION: 'APPEND_MESSAGE_TO_CONVERSATION',
  UPDATE_CONVERSATION_FROM_WS_MESSAGE: 'UPDATE_CONVERSATION_FROM_WS_MESSAGE',
}

const initialState = {
  entities: [],
  selected: null,
  selectedId: null,
  previousSelectedId: null,
  loading: false,
  lastError: null,
  selectedMessages: [],
  lastMessagesError: null,
  lastFetch: null,
  lastMessagesFetch: null,
  open: false,
  cachedEntities: {},
  searchValue: '',
  messsagesByConversation: {},
}

export default {
  name: 'conversations',
  reducer: (state = initialState, action) => {
    let newArr = null
    let index = null
    let entitiesBuffer = {}
    let messagesBuffer = {}
    let newSelectedMessages = state.selectedMessages
    let newSelected = state.selected
    switch (action.type) {
    case types.CLEAR_CONVERSATIONS_DATA:
      localStorage.setItem('cachedConversations', JSON.stringify({}))
      localStorage.setItem('cachedMessagesByConversation', JSON.stringify({}))
      return { ...initialState }
    case types.GET_CONVERSATIONS_START:
      return {
        ...state,
        loading: true,
      }
    case types.GET_CONVERSATIONS_ERROR:
      return {
        ...state,
        loading: false,
        lastError: Date.now(),
      }
    case types.GET_CONVERSATIONS_SUCCESS:
      entitiesBuffer = state.cachedEntities
      action.payload?.forEach(conv => entitiesBuffer[conv.id] = conv)
      localStorage.setItem('cachedConversations', JSON.stringify(entitiesBuffer))
      return {
        ...state,
        loading: false,
        lastFetch: Date.now(),
        entities: action.payload,
        lastError: null,
      }
    case types.GET_CONVERSATION_MESSAGES_START:
      return {
        ...state,
        loading: true,
      }
    case types.GET_CONVERSATION_MESSAGES_ERROR:
      return {
        ...state,
        loading: false,
        lastMessagesError: Date.now(),
      }
    case types.GET_CONVERSATION_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedMessages: action.payload,
        lastMessagesFetch: Date.now(),
        lastError: null,
      }
    case types.OPEN_MESSAGES_DRAWER:
      return {
        ...state,
        open: true,
      }
    case types.CLOSE_MESSAGES_DRAWER:
      return {
        ...state,
        open: false,
        selected: null,
        selectedId: null,
        selectedMessages: [],
      }
    case types.SET_SELECTED_CONVERSATION:
      return {
        ...state,
        selected: action.payload,
        selectedId: action.payload.id,
        previousSelectedId: state?.selectedId,
      }
    case types.CLEAR_SELECTED_CONVERSATION:
      return {
        ...state,
        selected: null,
        selectedId: null,
        previousSelectedId: state.selectedId,
      }
    case types.SET_SELECTED_MESSAGES:
      return {
        ...state,
        selectedMessages: action.payload,
      }
    case types.CLEAR_SELECTED_MESSAGES:
      return {
        ...state,
        selectedMessages: [],
      }
    case types.SET_CONVERSATIONS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case types.SET_CONVERSATIONS_IDLE:
      return {
        ...state,
        loading: false,
      }
    case types.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      }
    case types.CLEAR_SEARCH_VALUE:
      return {
        ...state,
        searchValue: '',
      }
    case types.ADD_CONVERSATION:
      return {
        ...state,
        entities: [action.payload, ...state.entities]
      }
    case types.CLEAR_CACHED_CONVERSATIONS:
      localStorage.setItem('cachedConversations', JSON.stringify({}))
      return {
        ...state,
        cachedEntities: {},
      }
    case types.CLEAR_CACHED_MESSAGES_BY_CONVERSATION:
      localStorage.setItem('cachedMessagesByConversation', JSON.stringify({}))
      return {
        ...state,
        messsagesByConversation: {},
      }
    case types.SET_MESSAGES_BY_CONVERSATION:
      return {
        ...state,
        messsagesByConversation: action.payload,
      }
    case types.SET_MESSAGES_OF_CONVERSATION:
      messagesBuffer = { ...state.messsagesByConversation }
      messagesBuffer[action.payload.conversationId] = action.payload.messages
      globalThis.localStorage.setItem('cachedMessagesByConversation', JSON.stringify(messagesBuffer))
      return {
        ...state,
        messsagesByConversation: messagesBuffer,
      }
    case types.APPEND_MESSAGE_TO_CONVERSATION:
      messagesBuffer = { ...state.messsagesByConversation }
      messagesBuffer[action.payload.conversation.id] = [...messagesBuffer[action.payload.conversation.id], action.payload]
      globalThis.localStorage.setItem('cachedMessagesByConversation', JSON.stringify(messagesBuffer))
      newSelectedMessages = [...state.selectedMessages]
      if (state.selectedId === action.payload.conversation.id) newSelectedMessages = [...messagesBuffer[action.payload.conversation.id]]
      return {
        ...state,
        messsagesByConversation: messagesBuffer,
        selectedMessages: newSelectedMessages,
      }
    case types.UPDATE_CONVERSATION_FROM_WS_MESSAGE:
      index = state.entities.findIndex(conversation => conversation.id === action.payload.conversation.id)
      entitiesBuffer = { ...state.cachedEntities }
      entitiesBuffer[action.payload.conversation.id] = { ...action.payload.conversation }
      globalThis.localStorage.setItem('cachedConversations', JSON.stringify(entitiesBuffer))
      if (index === -1) return { ...state, cachedEntities: { ...entitiesBuffer } }
      newArr = [...state.entities]
      newArr[index] = action.payload.conversation
      if (state.selectedId === action.payload.conversation.id) newSelected = { ...action.payload.conversation }
      return {
        ...state,
        cachedEntities: { ...entitiesBuffer },
        entities: [...newArr],
        selected: newSelected,
        selectedId: newSelected?.id ?? null,
      }
    default:
      return state
    }
  },
  selectConversationsRaw: state => state.conversations,
  selectConversations: state => state.conversations.entities,
  selectCurrentConversation: state => state.conversations.selected,
  selectCurrentConversationId: state => state.conversations.selectedId,
  selectConversationsOpen: state => state.conversations.open,
  selectCurrentMessages: state => state.conversations.selectedMessages,
  selectConversationsSearchValue: state => state.conversations.searchValue,
  selectMesssagesByConversation: state => state.conversations.messsagesByConversation,
  selectAllConversationsUnreads: state => {
    const conversationEntities = state.conversations.entities
    return conversationEntities.reduce((acc, convo) => acc + convo.unreads, 0)
  },
  selectValidNewConversationUsers: state => {
    const auth = state.auth
    const users = auth.users
    if (!users) return []
    const conversations = state.conversations.entities
    if (!conversations) return users
    if (!Array.isArray(conversations)) return []
    const currentUserId = state.auth?.user?.user?.id
    if (!currentUserId) return []
    const userIdsWithConversation = conversations
      .map(conv => conv.users.map(user => user.id))
      .flat()
      .filter(id => id !== currentUserId)
    const validUsers = users.filter(user => !userIdsWithConversation.includes(user.id) && user.id !== currentUserId)
    return validUsers
  },
  doClearConversationsData: () => ({ dispatch }) => dispatch({ type: types.CLEAR_CONVERSATIONS_DATA }),
  doOpenConversations: () => async ({ dispatch, store }) => {
    await store.doFetchUsers()
    dispatch({ type: types.OPEN_MESSAGES_DRAWER })
  },
  doCloseConversations: () => ({ dispatch }) => dispatch({ type: types.CLOSE_MESSAGES_DRAWER }),
  doSetMessagesByConversation: data => ({ dispatch }) => dispatch({ type: types.SET_MESSAGES_BY_CONVERSATION, payload: data }),
  doSetMessagesOfConversation: (conversationId, messages) => ({ dispatch }) => dispatch(
    { type: types.SET_MESSAGES_OF_CONVERSATION, payload: { conversationId, messages} }
  ),
  doAppendMessageOfConversation: message => ({ dispatch }) => dispatch({ type: types.APPEND_MESSAGE_TO_CONVERSATION, payload: message }),
  doUpdateConversationFromMessage: message => ({ dispatch }) => dispatch({ type: types.UPDATE_CONVERSATION_FROM_WS_MESSAGE, payload: message }),
  doSetSelectedConversation: conv => ({ dispatch, store }) => {
    dispatch({ type: types.SET_SELECTED_CONVERSATION, payload: conv })
    const messages = store.selectMesssagesByConversation()[conv.id]
    store.doSetSelectedMessages(messages)
  },
  doSetSelectedMessages: messages => ({ dispatch }) => dispatch({ type: types.SET_SELECTED_MESSAGES, payload: messages }),
  doMarkAsReadBulk: messagesIds => async ({ dispatch, store }) => {
    dispatch({ type: types.MARK_BULK_AS_READ })
    dispatch({ type: types.SET_CONVERSATIONS_LOADING })
    const access = store.selectAccessToken()
    try {
      await axios.post('/api/messages/bulkRead/', messagesIds, tokenHeader(access))
      await store.doFetchConversations()
      await store.doFetchSelectedConversationMessages()
      dispatch({ type: types.SET_CONVERSATIONS_IDLE })
    } catch (error) {
      console.log(error)
    }
  },
  doAddConversation: conv => async ({ dispatch, store }) => {
    dispatch({ type: types.SET_CONVERSATIONS_LOADING })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post('/api/conversations/', conv, tokenHeader(access))
      dispatch({ type: types.ADD_CONVERSATION, payload: res.data })
      store.doSetMessagesOfConversation(res.data.id, [])
      store.doSetSelectedConversation(res.data)
      dispatch({ type: types.SET_CONVERSATIONS_IDLE })
    } catch (error) {
      dispatch({ type: types.SET_CONVERSATIONS_IDLE })
      dispatch({ type: types.ADD_CONVERSATION_ERROR })
    }
  },
  doMarkTheirsAsRead: conv => async ({ dispatch, store }) => {
    dispatch({ type: types.MARK_THEIRS_AS_READ })
    dispatch({ type: types.SET_CONVERSATIONS_LOADING })
    const access = store.selectAccessToken()
    try {
      await axios.post(`/api/conversations/${conv.id}/markTheirsAsRead/`, null, tokenHeader(access))
      await store.doFetchSelectedConversationMessages()
      await store.doFetchConversations()
      dispatch({ type: types.SET_CONVERSATIONS_IDLE })
    } catch (error) {
      console.log(error)
      dispatch({ type: types.SET_CONVERSATIONS_IDLE })
    }
  },
  doFetchConversations: () => async ({ dispatch, store }) => {
    dispatch({ type: types.GET_CONVERSATIONS_START })
    const access = store.selectAccessToken()
    const searchVal = store.selectConversationsSearchValue()
    let url = '/api/conversations/'
    url = searchVal !== '' ? `${url}?search=${searchVal}` : url
    let res
    try {
      res = await axios.get(url, tokenHeader(access))
      dispatch({
        type: types.GET_CONVERSATIONS_SUCCESS,
        payload: res.data,
      })
      await store.doFetchAllConversationMessages()
    } catch (error) {
      dispatch({ type: types.GET_CONVERSATIONS_ERROR })
    }
  },
  doSetConversationsSearchValue: value => async ({ dispatch, store }) => {
    dispatch({ type: types.SET_SEARCH_VALUE, payload: value })
    await store.doFetchConversations()
  },
  doClearCachedConversations: () => ({ dispatch }) => dispatch({ type: types.CLEAR_CACHED_CONVERSATIONS }),
  doPostMessageToConversation: (message, clearInput) => async ({ store, dispatch }) => {
    dispatch({ type: types.SET_CONVERSATIONS_LOADING })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post('/api/messages/', message, tokenHeader(access))
      await store.doAppendMessageOfConversation(res.data)
      clearInput('')
      await store.doFetchSelectedConversationMessages()
      await store.doFetchConversations()
      dispatch({ type: types.SET_CONVERSATIONS_IDLE })
    } catch (error) {
      store.doSetSnackbarFail('Error al enviar mensaje.')
      dispatch({ type: types.SET_CONVERSATIONS_IDLE })
      if (process.env.REACT_APP_DEBUG === 'true') throw error
    }
  },
  doFetchAllConversationMessages: () => async ({ dispatch, store }) => {
    const conversations = store.selectConversations()
    const access = store.selectAccessToken()
    if (!Array.isArray(conversations)) return
    conversations.forEach(async conversation => {
      dispatch({ type: types.GET_CONVERSATION_MESSAGES_START })
      let res
      try {
        res = await axios.get(`/api/messages/conversation/?id=${conversation.id}`, tokenHeader(access))
        res.data.reverse()
        store.doSetMessagesOfConversation(conversation.id, res.data)
        dispatch({ type: types.SET_CONVERSATIONS_IDLE })
      } catch (error) {
        dispatch({ type: types.GET_CONVERSATION_MESSAGES_ERROR })
      }
    })
  },
  doFetchSelectedConversationMessages: () => async ({ dispatch, store }) => {
    dispatch({ type: types.GET_CONVERSATION_MESSAGES_START })
    const access = store.selectAccessToken()
    const selectedConversation = store.selectCurrentConversation()
    if (!selectedConversation) return
    let res
    try {
      res = await axios.get(`/api/messages/conversation/?id=${selectedConversation.id}`, tokenHeader(access))
      res.data.reverse()
      dispatch({ type: types.GET_CONVERSATION_MESSAGES_SUCCESS, payload: res.data })
      store.doSetMessagesOfConversation(selectedConversation.id, res.data)
      dispatch({ type: types.SET_CONVERSATIONS_IDLE })
    } catch (error) {
      dispatch({ type: types.GET_CONVERSATION_MESSAGES_ERROR })
      dispatch({ type: types.SET_CONVERSATIONS_IDLE })
    }
  },
  doFinishConversation: () => async ({ dispatch, store }) => {
    dispatch({ type: types.GET_CONVERSATION_MESSAGES_START })
    const access = store.selectAccessToken()
    const selectedConversation = store.selectCurrentConversation()
    if (!selectedConversation) return
    try {
      await axios.post(`/api/conversations/${selectedConversation.id}/finishConversation/`, {}, tokenHeader(access))
      store.doSetMessagesOfConversation(selectedConversation.id, [])
      store.doCloseConversations()
      store.doSetSnackbarSuccess('Conversación terminada')
      await store.doFetchSelectedConversationMessages()
    } catch (error) {
      dispatch({ type: types.SET_CONVERSATIONS_IDLE })
    }
  },
  reactShouldFetchConversations: createSelector(
    'selectConversationsRaw',
    'selectIsAuthenticated',
    'selectWebsocket',
    'selectAppTime',
    'selectIsOnline',
    'selectUserFlags',
    (conversationsRaw, isAuthenticated, wsRaw, appTime, isOnline, flags) => {
      if (!isAuthenticated) return
      if (!isOnline) return
      if (!flags?.includes('MESSAGING_V1')) return
      if (conversationsRaw.loading) return
      let shouldFetch = false
      const wsConnected = wsRaw.connected
      // if there are no entities, fetch
      let timePassedLastFetch
      if (conversationsRaw.lastFetch) {
        timePassedLastFetch = appTime - conversationsRaw.lastFetch
        if (timePassedLastFetch > 60000 && !wsConnected) {
          shouldFetch = true
        }
      }
      // if an error ocurred, wait 5s and retry
      if (conversationsRaw.lastError) {
        const timePassed = appTime - conversationsRaw.lastError
        if (timePassed > 5000) {
          shouldFetch = true
        }
      }
      // if entities is empty and there is no last fetch timestamp, fetch
      if (conversationsRaw.entities.length === 0 && !conversationsRaw.lastFetch) {
        shouldFetch = true
      }
      if (shouldFetch) return { actionCreator: 'doFetchConversations' }
    }
  ),
  init: async store => {
    const isAuth = store.selectIsAuthenticated()
    if (isAuth) await store.doFetchConversations()
    const cachedMessagesByConvRaw = globalThis.localStorage.getItem('cachedMessagesByConversation')
    if (cachedMessagesByConvRaw) {
      const cachedMessagesByConv = JSON.parse(cachedMessagesByConvRaw)
      store.doSetMessagesByConversation(cachedMessagesByConv)
    }
  },
}
