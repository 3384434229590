import React from 'react'
import PropTypes from 'prop-types'
import TableRow from './TableRow'
import { makeStyles } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import { validActionsPerKind } from '../../utils/ecg'
import Spinner from '../common/Spinner'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faMagnifyingGlass)

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    width: '100vw',
    minWidth: '100vw',
    maxWidth: '100vw',
    gap: 5,
    marginTop: 5,
    height: '100%',
    maxHeight: '60vh',
    borderRadius: 20,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    width: 'inherit',
  },
  emptyDiv: {
    gridArea: 'empty',
  },
  noDataDiv: {
    display: 'flex',
    gap: 20,
    flexDirection: 'row',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    height: '75vh',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    flexDirection: 'row',
    marginBottom: -10,
    gap: 30,
    backgroundColor: '#F7F7F7',
    width: '100%',
    paddingBottom: 10,
    zIndex: 400,
  },
  rowsDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    height: '100%',
    borderRadius: 20,
    overflowY: 'scroll',
  },
  fillerDiv: {
    backgroundColor: 'transparent',
    height: 65,
  },
}))

const TableMobile = props => {
  const {
    data,
    kind,
    ecgSearchValue,
    ecgsLoading: loading,
    ...rest
  } = props
  const {
    ecgsRaw,
    doSetEcgsCurrentPage,
    doSetEcgsPageSize,
  } = rest

  const noDataIcon = ecgSearchValue.length > 0 ? faMagnifyingGlass : 'fa-solid fa-clipboard-check'
  const getValidActions = validActionsPerKind[kind]
  const classes = useStyles()

  if (loading) {
    return (
      <div className={classes.noDataDiv}>
        <Spinner />
        <Typography sx={{ opacity: 0.2, fontSize: 20 }}>
          Cargando...
        </Typography>
      </div>
    )
  }

  return data.length ? (
    <div className={classes.root}>
      <div className={classes.pagination}>
        <TablePagination
          component='div'
          count={ecgsRaw.total}
          page={ecgsRaw.currentPage - 1}
          onPageChange={(_, newPage) => doSetEcgsCurrentPage(newPage + 1)}
          rowsPerPage={ecgsRaw.pageSize}
          onRowsPerPageChange={e => doSetEcgsPageSize(e.target.value)}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          labelRowsPerPage=''
          showFirstButton
          showLastButton
        />
      </div>
      <div className={classes.rowsDiv}>
        {data.map((ecg, index) => (
          <TableRow
            key={`table-${ecg.id}`}
            index={index}
            isLastRow={index === data.length - 1}
            ecg={ecg}
            kind={kind}
            getValidActions={getValidActions}
            {...rest}
          />
        ))}
      </div>
    </div>
  ) : (
    <div className={classes.noDataDiv}>
      <FontAwesomeIcon
        icon={noDataIcon}
        size='3x'
        opacity={0.1}
        bounce
        style={{ animationIterationCount: 1 }}
      />
      <Typography sx={{ opacity: 0.2, fontSize: 20 }}>
        {ecgSearchValue.length ? 'No hay resultados para ésta búsqueda' : '¡Nada más por hacer!'}
      </Typography>
    </div>
  )
}

TableMobile.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdOn: PropTypes.string.isRequired,
  })),
  ecgSearchValue: PropTypes.string,
}

TableMobile.defaultProps = {
  data: [],
}

export default TableMobile
