import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { styles } from './mobileStyles'
import Profile from '../components/layout/Profile'
import SearchBar from '../components/common/SearchBar'
import Dropdown from '../components/common/Dropdown'
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { IconButton } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useConnect } from 'redux-bundler-hook'
import ECGLogo from '../assets/static/img/logo_mobile.png'
import { ReactComponent as Filter } from '../assets/static/icons/filter.svg'
import { filterOptionsByKind } from '../bundles/filters'
import MobileNotificationDrawer from './MobileNotificationDrawer'

const useStyles = makeStyles(styles, { name: 'layout' })

const Layout = props => {
  const {
    children,
    doLogout,
    kind,
    filtersRaw,
    ecgsLoading,
    doUpdateFilter,
    ecgSearchValue,
    doSetSearchValue,
    doSetEcgsLoading,
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const openDropdown = Boolean(anchorEl)
  const [openNotifications, setOpenNotifications] = useState(false)
  const filterOptions = filterOptionsByKind[kind]

  const classes = useStyles()
  return (
    <div className={classes.layoutDiv}>
      <MobileNotificationDrawer
        open={openNotifications}
        onOpen={() => setOpenNotifications(true)}
        onClose={() => setOpenNotifications(false)}
      />
      <div className={classes.header}>
        <div className={classes.header1}>
          <Profile
            iconColor='#FFF'
            fontColor='#FFF'
            iconSize='30px'
          />
          {/* <IconButton sx={{ padding: '0' }}>
            <HelpOutlineIcon sx={{ color: '#FFF', fontSize: '17px', padding: '0', width: '25px' }} />
          </IconButton> */}
        </div>
        <div className={classes.header2}>
          <SearchBar
            isMobile={true}
            placeholder='Buscar...'
            searchValue={ecgSearchValue}
            setSearchValue={doSetSearchValue}
            iconSize='18px'
            height='40px'
            width='100%'
            loading={ecgsLoading}
            debounceTime={600}
            setIsLoading={doSetEcgsLoading}
          />
          <IconButton
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            <Filter
              fill='#FFF'
              stroke='#FFF'
              width='25px'
              height='25px'
              aria-expanded={open ? 'true' : undefined}
            />
          </IconButton>
          <Dropdown
            anchorEl={anchorEl}
            open={openDropdown}
            options={filterOptions}
            setAnchorEl={setAnchorEl}
            onChange={(e, value) => doUpdateFilter(value)}
            onClose={() => setAnchorEl(null)}
            initialKey={filtersRaw.value}
          />
        </div>
      </div>
      <div className={classes.content}>
        {children}
      </div>
      <div className={classes.menu}>
        <IconButton
          onClick={() => doLogout()}
        >
          <LogoutIcon
            sx={{ color: '#F94654', transform: 'scaleX(-1)' }}
          />
        </IconButton>
        <img src={ECGLogo} className={classes.logo} />
        <IconButton onClick={() => setOpenNotifications(true)}>
          <NotificationsIcon sx={{ color: '#F94654' }} />
        </IconButton>
      </div>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectEcgSearchValue',
    'selectEcgsLoading',
    'selectFiltersRaw',
    'selectKind',
    'doSetEcgsLoading',
    'doLogout',
    'doUpdateFilter',
    'doSetSearchValue',
  )
  return <Layout {...props} {...connectedProps} />
}
