import React from 'react'

import classNames from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const stringables = ['string', 'number']
export const getNodeText = node => {
  if (stringables.includes(typeof node)) {
    return String(node)
  }
  if (Array.isArray(node)) {
    return node.map(getNodeText).join('')
  }
  if (typeof node === 'object' && node?.props?.children) {
    return getNodeText(node.props.children)
  }
  return ''
}

const displayName = 'T'

/**
 *
 * @param {import("react").FC} Original
 * @param {Array<{ lite: ?bool, bold: ?bool }>} variants
 * @param {string} [baseName='Aroya/Typography']
 * @returns {import("react").FC}
 */
const withVariants = (Original, variants, baseName = displayName) => {
  Original.displayName = baseName
  Original.name = baseName
  return Object.assign(
    Original,
    Object.entries(variants).reduce((finalVariants, [name, variantProps]) => {
      const Variant = React.forwardRef((props, ref) => <Original {...props} {...variantProps} ref={ref} />)
      Variant.displayName = `${baseName}/${name}`
      Variant.name = `${baseName}/${name}`
      return {
        ...finalVariants,
        [name]: Variant
      }
    }, {})
  )
}

const useStyles = makeStyles(theme => ({
  bold: {
    fontFamily: theme.typography?.fontFamily || 'roboto',
    // fontFamily: theme.typography?.fontFamily || 'freight-sans-pro',
    fontWeight: 'bold',
  },
  lite: {
    fontFamily: theme.typography?.fontFamily || 'roboto',
    // fontFamily: theme.typography?.fontFamily || 'freight-sans-pro',
    fontWeight: 'normal',
  },
}), { name: displayName })

const liteProps = { lite: true }
const boldProps = { bold: true }
/**
 * A typography wrapper that provides shortcuts for commonly used variants
 * @alias T
 * @component
 * @composes
 * from: '@material-ui/core/Typography'
 * link: https://material-ui.com/api/typography/#props
* @example
 * {() => (
 *  <>
 *    <Box display="flex" flexWrap="wrap" alignItems="center" style={{ gap: '1rem' }}>
 *      <T>T</T>
 *      {Object.entries(T).filter(([key]) => key.match(/^[A-Z]/)).map(([key, Component]) => (
 *        <>
 *          <Component key={`T.${key}`}>{`T.${key}`}</Component>
 *          {Object.entries(Component).filter(([cKey]) => cKey.match(/^[A-Z]/)).map(([cKey, SubComponent]) => (
 *            <>
 *              <SubComponent key={`T.${key}.${cKey}`}>{`T.${key}.${cKey}`}</SubComponent>
 *              {Object.entries(SubComponent).filter(([sKey]) => sKey.match(/^[A-Z]/)).map(([sKey, SubSubComponent]) => (
 *                <SubSubComponent key={`T.${key}.${cKey}.${sKey}`}>{`T.${key}.${cKey}.${sKey}`}</SubSubComponent>
 *              ))}
 *            </>
 *          ))}
 *        </>
 *      ))}
 *    </Box>
 *    <T.H1>My Page Title</T.H1>
 *    <T.H2.Lite color="primary">My (Not Bold) 2nd-level Heading in Primary Color</T.H2.Lite>
 *    <T>This is a default styled paragraph with textSecondary color.</T>
 *    <T.Body>This is a default body paragraph with textPrimary color.</T.Body>
 *    <T.Body.Bold>This is a default body paragraph with textPrimary color and a bold font.</T.Body.Bold>
 *  </>
 * )}
 * @property {import("react").FC} Bold
 * @property {import("react").FC} H1
 * @property {import("react").FC} H1.Lite
 * @property {import("react").FC} H2
 * @property {import("react").FC} H2.Lite
 * @property {import("react").FC} H3
 * @property {import("react").FC} H3.Lite
 * @property {import("react").FC} H4
 * @property {import("react").FC} H4.Lite
 * @property {import("react").FC} Subtitle
 * @property {import("react").FC} Subtitle.Lite
 * @property {import("react").FC} Body
 * @property {import("react").FC} Body.Bold
 * @property {import("react").FC} Tiny
 * @property {import("react").FC} Tiny.Bold
 */
const Base = React.forwardRef(({ bold, className, lite, title, tooltip, children, ...passthru }, ref) => {
  const classes = useStyles()
  return (
    <Typography
      title={title || (tooltip && getNodeText(children)) || undefined}
      {...passthru}
      className={classNames({
        [classes.bold]: Boolean(bold),
        [classes.lite]: Boolean(lite),
        [className]: Boolean(className)
      })}
      ref={ref}
    >
      {children}
    </Typography>
  )
})
Base.displayName = displayName
Base.propTypes = {
  /** Use bold font weight and typeface */
  bold: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  /** Use normal font weight and typeface */
  lite: PropTypes.bool,
  /** Title to show in tooltip on hover */
  title: PropTypes.string,
  /** Should we auto-generate a title tooltip */
  tooltip: PropTypes.bool,
}
Base.defaultProps = {
  bold: false,
  children: null,
  className: undefined,
  lite: false,
  title: null,
  tooltip: false,
}

Object.assign(Base, {
  Bold: React.forwardRef((props, ref) => <Base ref={ref} {...props} bold />),
  // H1 through H6
  ...Array.from({ length: 6 }, (_, index) => index + 1).reduce((headingTypes, headingLevel) => ({
    ...headingTypes,
    [`H${headingLevel}`]: withVariants(
      React.forwardRef((props, ref) => <Base {...props} variant={`h${headingLevel}`} ref={ref} />),
      { Lite: liteProps },
      `${displayName}/H${headingLevel}`
    ),
  }), {}),
  Subtitle: withVariants(
    React.forwardRef((props, ref) => <Base color="textPrimary" {...props} variant="subtitle1" ref={ref} />),
    { Lite: liteProps },
    'Subtitle'
  ),
  Body: withVariants(
    React.forwardRef((props, ref) => <Base color="textPrimary" align="left" {...props} variant="body1" ref={ref} />),
    { Bold: boldProps },
    'Body'
  ),
  Tiny: withVariants(
    React.forwardRef((props, ref) => <Base {...props} variant="body2" ref={ref} />),
    { Bold: boldProps },
    'Tiny'
  )
})

export default Base
