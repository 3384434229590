// eslint-disable-next-line no-unused-vars
import axios from 'axios'

const types = {
  FETCH_DEVICE_FILES_START: 'FETCH_DEVICE_FILES_START',
  FETCH_DEVICE_FILES_SUCCESS: 'FETCH_DEVICE_FILES_SUCCESS',
  FETCH_DEVICE_FILES_ERROR: 'FETCH_DEVICE_FILES_ERROR',
  SET_DEVICE_FILES_DIALOG_OPEN: 'SET_DEVICE_FILES_DIALOG_OPEN',
  SET_DEVICE_FILES_SELECTED_DEVICE: 'SET_DEVICE_FILES_SELECTED_DEVICE',
  SET_DEVICE_FILES_PAGE: 'SET_DEVICE_FILES_PAGE',
}

const initialState = {
  items: [],
  next: null,
  previous: null,
  page: 1,
  total: null,
  totalPages: null,
  loading: false,
  lastFetch: null,
  lastError: null,
  lastErrorData: null,
  openTableViewDialog: false,
  selectedTableItem: null,
  searchValue: '',
  lastSearchValueSet: null,
  cachedItems: {},
  pageSize: 30,
  selectedItems: [],
  device: null,
  allSelected: false,
  filterParams: {},
}

export default {
  name: 'deviceFiles',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.FETCH_DEVICE_FILES_START:
      return {
        ...state,
        loading: true,
        lastError: null,
        lastErrorData: null,
      }
    case types.FETCH_DEVICE_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.items,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        total: action.payload.total,
        totalPages: action.payload.totalPages,
        lastFetch: Date.now(),
      }
    case types.FETCH_DEVICE_FILES_ERROR:
      return {
        ...state,
        loading: false,
        lastError: action.payload.error,
        lastErrorData: action.payload.data,
      }
    case types.SET_DEVICE_FILES_DIALOG_OPEN:
      return {
        ...state,
        openTableViewDialog: action.payload,
      }
    case types.SET_DEVICE_FILES_SELECTED_DEVICE:
      return {
        ...state,
        device: action.payload,
      }
    case types.SET_DEVICE_FILES_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    default:
      return state
    }
  },
  selectDeviceFilesItems: state => state.deviceFiles.items,
  selectDeviceFilesRaw: state => state.deviceFiles,
  selectDeviceFilesDevice: state => state.deviceFiles.device,
  selectDeviceFilesLoading: state => state.deviceFiles.loading,
  selectDeviceFilesDialogOpen: state => state.deviceFiles.openTableViewDialog,
  doSetDeviceFilesSelectedDevice: device => ({ dispatch }) => {
    dispatch({ type: types.SET_DEVICE_FILES_SELECTED_DEVICE, payload: device ?? null })
  },
  doSetDeviceFilesDialogOpen: open => ({ dispatch }) => {
    dispatch({ type: types.SET_DEVICE_FILES_DIALOG_OPEN, payload: open })
  },
  doSetDeviceFilesPage: page => ({ dispatch }) => {
    dispatch({ type: types.SET_DEVICE_FILES_PAGE, payload: page })
  },
  // doFetchDeviceFiles: () => async ({ dispatch, store }) => {
  //   dispatch({ type: types.FETCH_DEVICE_FILES_START })
  //   store.doSetAppLoading()
  //   const device = store.selectDeviceFilesDevice()
  //   if (!device) {
  //     store.doSetSnackbarFail('No se ha seleccionado un dispositivo')
  //     return false
  //   }
  //   const headers = {
  //     'Authorization': `Token ${device?.config?.fileServer?.token}`,
  //   }
  //   try {
  //     const response = await axios.get(`http://${device.ip}:${device.port}/list`, { headers, timeout: 5000 })
  //     dispatch({ type: types.FETCH_DEVICE_FILES_SUCCESS, payload: response.data })
  //     store.doSetAppIdle()
  //     return true
  //   } catch (error) {
  //     dispatch({ type: types.FETCH_DEVICE_FILES_ERROR, payload: error })
  //     store.doSetAppIdle()
  //     return false
  //   }
  // },
  doFetchDeviceFiles: (device) => async () => {
    try {
      window.open(`http://${device.ip}:${device.port}/list`, '_blank')
      return true
    } catch (error) {
      return false
    }
  },
  doFetchDeviceSingleFile: (file) => async ({ store }) => {
    const device = store.selectDeviceFilesDevice()
    if (!device) {
      store.doSetSnackbarFail('No se ha seleccionado un dispositivo')
      return false
    }
    const headers = {
      'Authorization': `Token ${device?.config?.fileServer?.token}`,
    }
    try {
      // Make a fetch request to the Flask endpoint that serves the file
      const response = await fetch(`http://${device.ip}:${device.port}/${file.endpoint}`, { headers })
      const fileBlob = await response.blob() // Extract the file content from the response

      // Create a URL for the Blob object
      const fileUrl = URL.createObjectURL(fileBlob)

      // Create a link with download attribute and set its href to the file URL
      const link = document.createElement('a')
      link.href = fileUrl
      link.download = file.name
      document.body.appendChild(link)

      // Programmatically click the link to trigger the file download
      link.click()

      // Clean up: remove the link and revoke the URL
      document.body.removeChild(link)
      URL.revokeObjectURL(fileUrl)
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }
}
