import createTableBundle from '../tableBundle'

const config = {
  bundleName: 'Document',
  camelCaseBundleName: 'documentsAdminTable',
  verboseName: 'documento',
  url: 'documents',
  allowDelete: true,
}

const baseBundle = createTableBundle(config)

export default {
  ...baseBundle,
}
