export const styles = theme => ({
  layoutDiv: {
    backgroundColor: theme.palette.secondary.main,
    display: 'grid',
    // eslint-disable-next-line max-len
    gridTemplateAreas: '"nav content"',
    gridTemplateColumns: '5% auto',
    minHeight: '100vh',
  },
  nav: {
    backgroundColor: theme.palette.secondary.main,
    gridArea: 'nav',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(5),
  },
  logoutButton: {
    marginTop: 'auto',
    marginBottom: 20,
  },
  content: {
    backgroundColor: theme.palette.backgroundColor.main,
    // borderRadius: '84px 0px 0px 84px',
    gridArea: 'content',
    minHeight: '100vh',
    padding: '20px',
    // borderLeft: 'solid 1px #borderRadius',
  },
  paragraph: {
    color: 'primary'
  },
  logoDiv: {
    display: 'flex',
    width: '100%',
  },
  logo: {
    width: '66px',
    marginTop: 30,
  }
})
