import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import Layout from './LayoutWrapper'
import { Helmet } from 'react-helmet'

export const getDataByKey = (params, user, key) => {
  switch (key) {
  case 'username':
    return user?.fullName
  case 'ecgId':
    return params?.ecgId
  default:
    return ''
  }
}

const App = props => {
  const {
    route,
    isAuthenticated,
    // appTitle,
    // user,
  } = props
  const { component: Component, titleTemplate } = route
  // TODO: add an extra permissions prop to each route and check if the current user (if authenticated) can be admitted
  const pageTitle = `CIDC - ${titleTemplate}`

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {isAuthenticated ? (
        <Layout component={Component} />
      ) : (
        <Component />
      )}
    </>
  )
}

export default () => {
  const connectedProps = useConnect(
    'selectRoute',
    'selectPathname',
    'selectIsAuthenticated',
    'doUpdateUrl',
    'selectRouteParams',
    'selectUser',
    'selectAppTitle',
  )
  return <App {...connectedProps} />
}
