import * as yup from 'yup'
import { parametersKeys, rythmOptions } from './formVars'
import { getSexValue } from './misc'

const DEFAULT_REQUIRED_MSG = 'Campo requerido.'

const baseValidator = {
  names: yup.string().required(),
  lastNames: yup.string().required(),
  birthDay: yup.number().max(31).min(1).required(DEFAULT_REQUIRED_MSG),
  birthMonth: yup.number().max(12).min(1).required(DEFAULT_REQUIRED_MSG),
  birthYear: yup.number().min(1900).required(DEFAULT_REQUIRED_MSG),
  lastECGDay: yup.number().max(31).min(1),
  lastECGMonth: yup.number().max(12).min(1),
  lastECGYear: yup.number().min(1900),
  sex: yup.string().oneOf(['MASCULINO', 'FEMENINO']).required(DEFAULT_REQUIRED_MSG),
  tas: yup.number().required(DEFAULT_REQUIRED_MSG),
  tad: yup.number().required(DEFAULT_REQUIRED_MSG),
  height: yup.number().required(DEFAULT_REQUIRED_MSG),
  weight: yup.number().required(DEFAULT_REQUIRED_MSG),
  smoker: yup.bool(),
  alcoholic: yup.bool(),
  diabetic: yup.bool(),
  hypertensive: yup.bool(),
  previousSurgery: yup.bool(),
  transfusions: yup.bool(),
  hepatitis: yup.bool(),
  heartDiseaseHistory: yup.bool(),
  cholesterol: yup.bool(),
  previousEcg: yup.bool(),
  resultNormal: yup.bool(),
  urgency: yup.bool(),
  redCode: yup.bool(),
  rejected: yup.bool(),
  accepted: yup.bool(),
  withInterpretation: yup.bool(),
  thirdAuthorization: yup.bool(),
  pacemaker: yup.bool(),
  pacemakerKind: yup.string(),
  checkReason: yup.string()
    .oneOf(
      [
        'PREOPERATORIO',
        'REVISIÓN GENERAL',
        'DOLOR PRECORDIAL',
        'DOLOR ENFERMEDAD CARDIACA',
      ],
    ).required(DEFAULT_REQUIRED_MSG),
  thirdName: yup.string(),
  relationship: yup.string(),
  notes: yup.string(),
  diagnosis: yup.string(),
  studioId: yup.string().required('Folio es requerido'),
  isReported: yup.bool(),
  hasTrace: yup.bool(),
  rythm: yup.string().oneOf(rythmOptions),
  frequency: yup.number(),
  frequencyUnit: yup.string(),
  pAxis: yup.number(),
  qrsAxis: yup.number(),
  tAxis: yup.number(),
  rR: yup.number(),
  pR: yup.number(),
  qrs: yup.number(),
  qtm: yup.number(),
  qtc: yup.number(),
  stT: yup.number(),
  isResponsible: yup.string(),
  isNew: yup.bool(),
}

const createConsentKeys = [
  'names',
  'lastNames',
  'birthDay',
  'birthMonth',
  'birthYear',
  'sex',
  'tas',
  'tad',
  'height',
  'weight',
  'pacemaker',
  'pacemakerKind',
  'lastECGDay',
  'lastECGMonth',
  'lastECGYear',
  'resultNormal',
  'checkReason',
  'thirdAuthorization',
  'thirdName',
  'relationship',
  'withInterpretation',
  'notes',
  'isResponsible',
  'studioId',
  'isNew',
]

export const consentFormValidation = yup.object().shape(Object.fromEntries(
  Object.entries(baseValidator).filter(([key]) => createConsentKeys.includes(key))
))

export const chewAPIEcgDataToForm = ecg => {
  const chewedData = {...ecg}
  const birthDate = new Date(Date.parse(ecg.patient.birthDate))
  chewedData.age = ecg.patient.age
  delete chewedData.patient
  chewedData.name = ecg.patient.name
  chewedData.email = ecg.patient.email
  chewedData.thirdEmail = ecg.patient.thirdEmail
  chewedData.sex = getSexValue(ecg.patient.sex)
  chewedData.patient = ecg.patient.id
  chewedData.birthDay = birthDate.getUTCDate()
  chewedData.birthMonth = birthDate.getMonth() + 1
  chewedData.birthYear = birthDate.getFullYear()
  chewedData.checkReason = ecg.checkReason ? ecg.checkReason.toUpperCase() : ''
  if (ecg.previousEcg) {
    const lastEcgDate = new Date(Date.parse(ecg.lastEcgDate))
    chewedData.lastECGDay = lastEcgDate.getUTCDate()
    chewedData.lastECGMonth = lastEcgDate.getMonth() + 1
    chewedData.lastECGYear = lastEcgDate.getFullYear()
  }
  const [tas, tad] = ecg.bloodPressure.split('/')
  chewedData.tas = tas
  chewedData.tad = tad
  parametersKeys.forEach(key => {
    if (chewedData[key] === -9999) chewedData[key] = '-'
  })
  chewedData.discountTag = ecg.discountTag?.id ?? null

  return chewedData
}
