import { createSelector } from 'redux-bundler'
import logo from '../assets/static/img/logo.png'
import { ENTITY_TO_BUNDLE_NAME } from './entity'

export const types = {
  WEBSOCKET_STARTING_CONNECTION: 'WEBSOCKET_START_CONNECTION',
  WEBSOCKET_ENDED_CONNECTION: 'WEBSOCKET_ENDED_CONNECTION',
  WEBSOCKET_CONNECTED: 'WEBSOCKET_CONNECTED',
  WEBSOCKET_DISCONNECTED: 'WEBSOCKET_DISCONNECTED',
  WEBSOCKET_ERROR: 'WEBSOCKET_ERROR',
  WEBSOCKET_MESSAGE_RECEIVED: 'WEBSOCKET_MESSAGE_RECEIVED',
  WEBSOCKET_CLEAR_MESSAGE: 'WEBSOCKET_CLEAR_MESSAGE',
  WEBSOCKET_NEW_NOTIFICATION: 'WEBSOCKET_NEW_NOTIFICATION',
  WEBSOCKET_NEW_MESSAGE: 'WEBSOCKET_NEW_MESSAGE',
  WEBSOCKET_MAX_CONNECTION_ATTEMPTS: 'WEBSOCKET_MAX_CONNECTION_ATTEMPTS',
  WEBSOCKET_RESET_ATTEMPTS: 'WEBSOCKET_RESET_ATTEMPTS',
  WEBSOCKET_DUMMY_ACTION: 'WEBSOCKET_DUMMY_ACTION',
  WEBSOCKET_ABORT_CONNECTION: 'WEBSOCKET_ABORT_CONNECTION',
}
export const MAX_CONNECTION_ATTEMPTS = 10
export const RETRY_TIME_AFTER_ERROR_MILISECONDS = 10000

const initialState = {
  socket: true,
  url: process.env.REACT_APP_WEBSOCKET_URL,
  connecting: false,
  connected: false,
  messageReceived: false,
  lastMessage: null,
  error: false,
  errorMsg: '',
  lastTry: null,
  lastError: null,
  failedAttempts: 0,
  abort: false,
  sound: '',
}

export default {
  name: 'websocket',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.WEBSOCKET_RESET_ATTEMPTS:
      return {
        ...state,
        abort: false,
        failedAttempts: 0,
      }
    case types.WEBSOCKET_ABORT_CONNECTION:
      return {
        ...state,
        abort: true,
        error: false,
      }
    case types.WEBSOCKET_MAX_CONNECTION_ATTEMPTS:
      return {
        ...state,
        abort: true,
      }
    case types.WEBSOCKET_STARTING_CONNECTION:
      return {
        ...state,
        connecting: true,
        abort: false,
        lastTry: Date.now(),
      }
    case types.WEBSOCKET_ENDED_CONNECTION:
      return {
        ...state,
        socket: false,
        connecting: false,
        connected: false,
      }
    case types.WEBSOCKET_CONNECTED:
      return {
        ...state,
        socket: true,
        connecting: false,
        connected: true,
        error: false,
        errorMsg: '',
        failedAttempts: 0,
        lastError: null,
      }
    case types.WEBSOCKET_DISCONNECTED:
      return {
        ...state,
        socket: false,
        connected: false,
        connecting: false,
      }
    case types.WEBSOCKET_ERROR:
      return {
        ...state,
        socket: false,
        connecting: false,
        connected: false,
        error: true,
        errorMsg: action.payload,
        lastError: Date.now(),
        failedAttempts: state.failedAttempts + 1,
      }
    case types.WEBSOCKET_MESSAGE_RECEIVED:
      return {
        ...state,
        lastMessage: action.payload,
        messageReceived: true,
      }
    case types.WEBSOCKET_CLEAR_MESSAGE:
      return {
        ...state,
        messageReceived: false,
        lastMessage: null,
      }
    default:
      return state
    }
  },
  selectWebsocket: state => state.websocket,
  selectWebsocketUrl: state => state.websocket.url,
  selectWebsocketConnected: state => state.websocket.connected,
  doWebsocketResetAttempts: () => ({ dispatch }) => dispatch({ type: types.WEBSOCKET_RESET_ATTEMPTS }),
  doWebsocketAbortConnection: () => ({ dispatch }) => dispatch({ type: types.WEBSOCKET_ABORT_CONNECTION }),
  doDispatchWsDummyAction: () => ({ dispatch }) => dispatch({ type: types.WEBSOCKET_DUMMY_ACTION }),
  doOnSocketOpen: () => ({ dispatch }) => dispatch({ type: types.WEBSOCKET_CONNECTED }),
  doOnSocketClose: () => ({ dispatch }) => dispatch({ type: types.WEBSOCKET_DISCONNECTED }),
  doOnSocketError: error => ({ dispatch, store }) => {
    const ws = store.selectWebsocket()
    dispatch({ type: types.WEBSOCKET_ERROR, payload: error })
    if (process.env.REACT_APP_DEBUG === 'true' && ws.failedAttempts > MAX_CONNECTION_ATTEMPTS) {
      const errorCode = error.message?.match(/\d{3}/)[0]
      store.doSetSnackbarFail(errorCode)
    }
  },
  doCreateSocket: () => ({ dispatch, store }) => {
    const wsBaseUrl = store.selectWebsocketUrl()
    const access = store.selectAccessToken()
    const socketUrl = `${wsBaseUrl}${access}`
    try {
      const socket = new WebSocket(socketUrl)
      socket.onerror = store.doOnSocketError
      socket.onopen = store.doOnSocketOpen
      socket.onclose = store.doOnSocketClose
      socket.onmessage = store.doOnSocketMessage
      globalThis.ecgWebsocket = socket
      dispatch({ type: types.WEBSOCKET_STARTING_CONNECTION })
    } catch (error) {
      console.error(error.message)
      dispatch({ type: types.WEBSOCKET_ERROR, payload: error })
    }
  },
  doOnSocketMessage: e => async ({ dispatch, store }) => {
    const obj = JSON.parse(e.data)
    console.log(e)
    const openedMessageDrawer = store.selectConversationsOpen()
    const canUpdateWs = store.selectCanUpdateWs()
    dispatch({ type: types.WEBSOCKET_MESSAGE_RECEIVED, payload: obj })
    const action = obj?.action
    const ecgType = obj?.ecgType
    const data = obj?.data
    if (process.env.REACT_APP_DEBUG === 'true') console.log('SOCKET ACTION', action, data?.id, e.data, e, obj)
    if (action === 'updated' && canUpdateWs) {
      dispatch({
        type: 'EDIT_ECG',
        payload: data,
      })
      await store.doFetchECGS()
    } else if (action === 'entity') {
      const bundle = ENTITY_TO_BUNDLE_NAME[obj?.entity]
      const actionCreator = `doUpdate${bundle}FromWs`
      store[actionCreator](data)
    } else if (action === 'created' && canUpdateWs) {
      dispatch({
        type: 'ADD_ECG',
        payload: data,
      })
      await store.doFetchECGS()
      if ('Notification' in window) {
        const notification = new Notification('Nuevo estudio', {
          icon: logo,
          badge: logo,
          body: data?.patient?.fullName,
        })
        notification.onclick = () => {
          if (data.id) window.open(`${window.location.origin}/estudio/${data?.id}/`, '_blank')
        }
      }
    } else if (ecgType === 'redCode') {
      await store.doFetchNotifications()
      store.doSetSnackbarInfo('Nueva notificación')
      if ('Notification' in window) {
        const notification = new Notification('CÓDIGO ROJO', {
          icon: logo,
          body: data?.patient?.fullName,
          badge: logo,
          requireInteraction: true,
        })
        notification.onclick = () => {
          if (data.id) window.open(`${window.location.origin}/estudio/${data?.id}/`, '_blank')
        }
      }
    } else if (action === 'notification') {
      dispatch({ type: types.WEBSOCKET_NEW_NOTIFICATION })
      const audioNotif = new Audio(process.env.PUBLIC_URL + '/notificationSound.mp3')
      store.doAppendNewNotification(data)
      store.doSetSnackbarInfo('Nueva notificación')
      audioNotif.play()
      if ('Notification' in window) {
        new Notification('Nueva notificación', {
          icon: logo,
          badge: logo,
          body: data.message,
        })
      }
    } else if (action === 'message') {
      store.doAppendMessageOfConversation(data)
      store.doUpdateConversationFromMessage(data)
      if (!openedMessageDrawer) store.doSetSnackbarInfo(`Nuevo mensaje de ${data.sender.fullName}.`)
      const audioMessage = new Audio(process.env.PUBLIC_URL + '/messageSound.mp3')
      audioMessage.play()
      if ('Notification' in window) {
        new Notification(`Mensaje de ${data.sender.fullName}:`, {
          icon: logo,
          body: data.content,
        })
      }
    }
    else return
  },
  doSetMaxConnectionAttemptsMessage: () => ({ dispatch }) => dispatch({ type: types.WEBSOCKET_MAX_CONNECTION_ATTEMPTS }),
  reactConnectWebsocket: createSelector(
    'selectIsAuthenticated',
    'selectUserFlags',
    'selectWebsocket',
    'selectAppTime',
    'selectIsOnline',
    (isAuth, flags, websocket, appTime, isOnline) => {
      if (!isAuth) return
      if (!isOnline) return
      if (!flags?.includes('REAL_TIME_WEBSOCKET')) return
      let shouldCreate = false
      if (websocket.abort) return
      if (websocket.failedAttempts > MAX_CONNECTION_ATTEMPTS) return { actionCreator: 'doSetMaxConnectionAttemptsMessage' }
      if (websocket.connecting) return
      if (websocket.connected) return
      if (websocket.lastError) {
        if (appTime - websocket.lastError > RETRY_TIME_AFTER_ERROR_MILISECONDS) shouldCreate = true
      }
      if (!websocket.lastTry) shouldCreate = true
      if (shouldCreate) return { actionCreator: 'doCreateSocket' }
    },
  ),
}
